<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"

////////////
// System //
////////////

const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()

const bexio_inbox_open = ref([])
const bexio_inbox_archived = ref([])
const bexio_inbox_section = ref("inbox")
const selected_bexio_files = ref([])
const downloadBexioInboxDialog = ref(false)
const uploadPdfDialoag = ref(false)
const bexio_inbox = ref([])

const filter_bexio_inbox = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})

onMounted( async () => {
    init_data()
})

const init_data = async () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/senler/get-bexio-inbox")
    .then(response => {
        bexio_inbox_open.value = response.data
        if (bexio_inbox_section.value == "inbox") {
            bexio_inbox.value = bexio_inbox_open.value
        }
        loader.value = false
    })
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/senler/get-bexio-inbox-archived")
    .then(response => {
        bexio_inbox_archived.value = response.data
        if (bexio_inbox_section.value == "archive") {
            bexio_inbox.value = bexio_inbox_archived.value
        }
        loader.value = false
    })
}

const rename_bexio_file = (e) => {
    console.log("rename_bexio_file", e)
    if (e.newValue != e.value){
        let payload = {
            "id": e.data.id,
            "filename": e.newValue
        }
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/senler/bexio-rename-file", payload)
        .then(response => {
            toast.add({severity:'success', summary: 'Datei umbenannt', detail: 'Datei wurde erfolgreich umbenannt', life: 3000})
            init_data()
        })
    }
}

const download_file = (e) => {
    loader.value = true
    let payload = e
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/senler/get-bexio-file", payload)
    .then(response => {
        if(response.data.status == "success"){
            toast.add({severity:'success', summary: 'Import erfolgreich', detail: response.data.message, life: 3000})
            init_data()
            loader.value = false
        } else {
            toast.add({severity:'error', summary: 'Fehler', detail: 'Rechnung konnte nicht heruntergeladen werden', life: 3000})
            loader.value = false
        }
    })
}

const download_files = (event) => {
    for (let key in event){
        download_file(event[key])
    }
}

const restore_file = (e) => {
    loader.value = true
    let payload = e
    console.log("download", payload)
    payload["channel"] = channel.value
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/krediflow/restore-bexio-invoice", payload)
    .then(response => {
        if(response.data.status == "success"){
            toast.add({severity:'success', summary: 'Rechnung wurde erfolgreich wiederhergestellt', detail: response.data.message, life: 3000})
            get_bexio_inbox()
            loader.value = false
        } else {
            toast.add({severity:'error', summary: 'Fehler', detail: 'Rechnung konnte nicht wiederhergestellt werden', life: 3000})
            loader.value = false
        }
    })
}

const restore_files = (event) => {
    loader.value = true
    for (let key in event){
        restore_file(event[key])
    }
}

const archive_file = (e) => {
    loader.value = true
    let payload = e
    console.log("download", payload)
    payload["channel"] = channel.value
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/krediflow/archive-bexio-invoice", payload)
    .then(response => {
        if(response.data.status == "success"){
            toast.add({severity:'success', summary: 'Rechnung wurde erfolgreich archiviert', detail: response.data.message, life: 3000})
            get_bexio_inbox()
            loader.value = false
        } else {
            toast.add({severity:'error', summary: 'Fehler', detail: 'Rechnung konnte nicht archiviert werden', life: 3000})
            loader.value = false
        }
    })
}

const archive_files = (event) => {
    loader.value = true
    for (let key in event){
        archive_file(event[key])
    }
}

const upload_bexio_pdf = async (e) => {
    loader.value = true;
    try {
        for (let i = 0; i < e.files.length; i++) {
            const reader = new FileReader();
            reader.readAsDataURL(e.files[i]);
            reader.onload = async () => {
                let payload = { filename: e.files[i].name, file: reader.result, channel: channel.value};
                await axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/krediflow/upload-bexio-pdf", payload)
                .then(response => {
                    if (response.data.status == "success") {
                        toast.add({severity: 'success', summary: 'PDF '+e.files[i].name+' wurde hochgeladen', detail: 'PDF Datei '+e.files[i].name+' wurde erfolgreich hochgeladen', life: 3000})
                    }
                })
            }
            if (i + 1 == e.files.length) {
                loader.value = false
                uploadPdfDialoag.value = false
            }
        }
    } catch (error) {
        console.error(error);
    }
}

const bexio_thumb_index = ref(0)
const bexio_thumb_index_change = (event) => {
    console.log("toggle_thumbnail", event)
    bexio_thumb_index.value = event.index
}

const swiss_date_time = (date) => {
    return new Date(date).toLocaleDateString('de-CH', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })
}

</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <Toast />
    <ConfirmDialog />
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <BlockUI :blocked="loader">
        <Toolbar>
            <template #start>
                <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                    <InputText v-model="filter_bexio_inbox['global'].value" placeholder="Bexio Posteingang durchsuchen" style="width: 300px" class="mr-4" />
                </span>
                <span v-if="bexio_inbox_section == 'inbox'" class="mr-4 font-bold">Bexio Posteingang<Badge :value="bexio_inbox_open.length" class="bg-gray-300 text-gray-600 ml-1" /></span>
                <span v-if="bexio_inbox_section != 'inbox'" @click="bexio_inbox_section = 'inbox', bexio_inbox = bexio_inbox_open, bexio_thumb_index = 0, selected_bexio_files = []" class="mr-4 cursor-pointer">Bexio Posteingang<Badge :value="bexio_inbox_open.length" class="bg-gray-300 text-gray-600 ml-1" /></span>
                <span v-if="bexio_inbox_section == 'archive'" class="mr-4 font-bold">Archiviert<Badge :value="bexio_inbox_archived.length" class="bg-gray-300 text-gray-600 ml-1" /></span>
                <span v-if="bexio_inbox_section != 'archive'" @click="bexio_inbox_section = 'archive', bexio_inbox = bexio_inbox_archived, bexio_thumb_index = 0, selected_bexio_files = []" class="mr-4 cursor-pointer">Archiviert<Badge :value="bexio_inbox_archived.length" class="bg-gray-300 text-gray-600 ml-1" /></span>
                <Button v-if="selected_bexio_files.length > 0 && bexio_inbox_section == 'inbox'" @click="download_files(selected_bexio_files)" v-tooltip.bottom="'Dateien importieren'" icon="pi pi-download" :label="String(selected_bexio_files.length) + ' x Dateien importieren'" class="p-button w-auto mr-4" />
                <Button v-if="selected_bexio_files.length > 0 && bexio_inbox_section == 'inbox'" @click="archive_files(selected_bexio_files)" v-tooltip.bottom="'Dateien archivieren'" icon="pi pi-folder" :label="String(selected_bexio_files.length) + ' x Dateien archivieren'" class="p-button w-auto mr-4" />
                <Button v-if="selected_bexio_files.length > 0 && bexio_inbox_section == 'archive'" @click="restore_files(selected_bexio_files)" v-tooltip.bottom="'Dateien in Posteingang verschieben'" icon="pi pi-refresh" :label="String(selected_bexio_files.length) + ' in Posteingang verschieben'" class="p-button w-auto" />
            </template>
            <template #end>
                <Button @click="uploadPdfDialoag = true" v-tooltip.bottom="'Rechnungen hochladen'" label="Hochladen" class="mr-2 p-button" icon="pi pi-upload" />
            </template>
        </Toolbar>
        <!--------------------------------------------------->
        <!------------------ DataTable ---------------------->
        <!--------------------------------------------------->
        <div class="flex">
            <div class="flex-auto">
                <DataTable class="mt-3 w-full" @row-click="bexio_thumb_index_change" editMode="cell" @cell-edit-complete="rename_bexio_file" v-model:selection="selected_bexio_files"  v-model:filters="filter_bexio_inbox" :value="bexio_inbox" :rows="50" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[50,100,200]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                    <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                    <Column field="created_at" header="Datum" sortable style="width: 12rem">
                        <template #body="slotProps">
                            <span>{{ swiss_date_time(slotProps.data.created_at) }}</span>
                        </template>
                    </Column>
                    <Column field="preview" style="width: 10rem">
                        <template #body="slotProps">
                            <div class="overflow-hidden shadow-2 w-9rem h-4rem" @mouseover="bexio_thumb_index = slotProps.index">
                                <img v-if="slotProps.data.preview" :src="slotProps.data.preview" />
                            </div>
                        </template>
                    </Column>
                    <Column field="name" header="Datei" sortable>
                        <template #editor="{ data, field }">
                            <InputText v-model="data[field]" class="w-full" />
                        </template>
                        <template #body="{ data, field }">
                            <div class="font-bold">{{ data[field]}}</div>
                            <div v-if="data['uploader_email']">{{ data['uploader_email'] }}</div>
                        </template>
                    </Column>
                    <Column field="uploader_email" header="Von" hidden />
                    <Column field="source_type" header="Herkunft">
                        <template #body="slotProps">
                            <span v-if="slotProps.data.source_type == 'email'">E-Mail</span>
                            <span v-if="slotProps.data.source_type == 'web'">Upload</span>
                        </template>
                    </Column>
                    <Column field="extension">
                        <template #body="slotProps">
                            <Button v-if="bexio_inbox_section == 'inbox'" :hidden="selected_bexio_files.length > 0" @click="download_file(slotProps.data)" v-tooltip.bottom="slotProps.data.extension.toUpperCase() + ' importieren'" icon="pi pi-download" class="p-button mr-2 mt-1" />
                            <Button v-if="bexio_inbox_section == 'inbox'" :hidden="selected_bexio_files.length > 0" @click="archive_file(slotProps.data)" v-tooltip.bottom="'Datei archivieren'" icon="pi pi-folder" class="p-button mr-2 mt-1" />
                            <Button v-if="bexio_inbox_section == 'archive'" :hidden="selected_bexio_files.length > 0" @click="restore_file(slotProps.data)" v-tooltip.bottom="'In Posteingang verschieben'" icon="pi pi-refresh" class="p-button" />
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div v-if="bexio_inbox" class="flex-auto p-2" style="width: 450px">
                <img v-if="bexio_inbox.length > 0" :src="bexio_inbox[bexio_thumb_index].preview" class=" shadow-3" />
            </div>
        </div>
    </BlockUI>
    <!-------------------------------------------------->
    <!-------------- PDF Upload Dialog ----------------->
    <!-------------------------------------------------->
    <Dialog header="PDF hochladen" v-model:visible="uploadPdfDialoag" :modal="true" class="">
        <div class="">
            <div class="">
                <FileUpload name="demo[]" :customUpload="true" @uploader="upload_bexio_pdf" :multiple="true" accept="application/pdf" previewWidth="0" :maxFileSize="10000000" chooseLabel="PDF auswählen" uploadLabel="Hochladen" cancelLabel="Abbrechen" class="cursor-pointer">
                    <template #empty>
                        <div v-if="!loader">
                            <div class="flex justify-content-center p-2"><i class="pi pi-upload h-14 w-14 text-primary-700" style="font-size: 4rem;"></i></div>
                            <div class="flex justify-content-center p-2">Hochladen per Drag & Drop</div>
                        </div>
                        <div class="flex justify-content-center" v-if="loader">
                            <ProgressSpinner style="width: 50px; height: 50px" strokeWidth="8" />
                            Upload läuft, das Fenster wird automatisch geschlossen
                        </div>
                    </template>
                </FileUpload>
            </div>
        </div>
    </Dialog>
</template>