<script setup>
import Users from '@/core/components/User/Admin/Admin-User-List.vue'
import AdminUseradd from '@/core/components/User/Admin/Admin-Useradd.vue'
import RolesEdit from '@/core/components/User/Admin/Admin-Role-Edit.vue'
import { useAuthStore } from '@/core/store/AuthStore';
const auth = useAuthStore();

</script>

<template>
    <div>
        <TabView class="col-12" ref="tabview4">
            <TabPanel>
                <template #header>
                    <i class="pi pi-user mr-2"></i>
                    <span class="mr-3">Benutzer</span>
                </template>
                <Users />
            </TabPanel>
            <TabPanel>
                <template #header>
                    <i class="pi pi-user mr-2"></i>
                    <span class="mr-3">Benutzer anlegen</span>
                </template>
                <AdminUseradd />
            </TabPanel>
            <TabPanel v-if="auth.userdata.user_roles[0] == 0">
                <template #header>
                    <i class="pi pi-users mr-2"></i>
                    <span class="mr-3">Rollen</span>
                </template>
                <RolesEdit />
            </TabPanel>
        </TabView>
    </div>
</template>