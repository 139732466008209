<script setup>
import { ref, onMounted, watch } from 'vue'
import axios from "axios"

const getQueryParam = key => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams.get(key);
}

const pdf_url = ref()
const token = ref()
const workflow_id = ref()

onMounted(() =>{
    token.value = getQueryParam('token')
    if(token.value){
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/senler/external-pdf', {token: token.value})
        .then(response => {
            if(response.data.status == 'success'){
                workflow_id.value = response.data.workflow_id
                pdf_url.value = response.data.pdf_url
            }
        })
    }
})
</script>

<template>
    <div class="h-screen min-h-screen">
        <iframe v-if="pdf_url" @mouseover="pdf_width" :src="pdf_url" style="width: 100%;height: 100%; border: none;">
            Oops! an error has occurred.
        </iframe>
    </div>
</template>