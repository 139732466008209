<script setup>
import { ref, onMounted, watch } from 'vue'
import axios from "axios"

const getQueryParam = key => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams.get(key);
}

const authed = ref(false)
const user_id = ref()
const token = ref()
const workflows = ref([])
const workflow_id = ref()
const active_workflow = {}

const check_approval = ref(false)
const check_refusal = ref(false)

const workflows_dialog = ref(false)
const pdf_dialog = ref(false)

const invoice_amount = ref(0)
const invoice_number = ref(null)
const invoice_date = ref(null)
const invoice_due_date = ref(null)
const supplier_name = ref(null)
const pdf_url = ref()
const comment = ref()

onMounted(() =>{
    token.value = getQueryParam('token')
    authed.value = localStorage.getItem('authed')
    if(token.value){
        /*
        if(!localStorage.getItem('authed')){
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/senler/external-auth', {token: token.value})
            .then(response => {
                if(response.data.status == 'success'){
                    localStorage.setItem('authed', true)
                }
            })
        }*/
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/senler/external-workflow', {token: token.value})
        .then(response => {
            if(response.data.status == 'success'){
                workflows.value = response.data.workflows
                if (workflows.value.length > 0) {
                    console.log(workflows.value[0])
                    active_workflow.value = workflows.value[0]
                    console.log("active workflow", active_workflow.value)
                    pdf_url.value = active_workflow.value.meta.download_url
                    invoice_amount.value = active_workflow.value.meta.invoice_amount
                    invoice_number.value = active_workflow.value.meta.invoice_number
                    invoice_date.value = active_workflow.value.meta.invoice_date
                    invoice_due_date.value = active_workflow.value.meta.invoice_due_date
                    supplier_name.value = active_workflow.value.meta.supplier_name
                }
            }
        })
    }
})

const load_workflow = (workflow) => {
    active_workflow.value = workflow
    console.log("active workflow", active_workflow.value)
    pdf_url.value = active_workflow.value.meta.download_url
    invoice_amount.value = active_workflow.value.meta.invoice_amount
    invoice_number.value = active_workflow.value.meta.invoice_number
    invoice_date.value = active_workflow.value.meta.invoice_date
    invoice_due_date.value = active_workflow.value.meta.invoice_due_date
    supplier_name.value = active_workflow.value.meta.supplier_name
    workflows_dialog.value = false
}

const click_approval = () => {
    check_refusal.value = false
}

const click_refusal = () => {
    check_approval.value = false
}

const swiss_date = (date) => {
    return new Date(date).toLocaleDateString('de-CH', { year: 'numeric', month: '2-digit', day: '2-digit' })
}


</script>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <div class="h-screen min-h-screen p-2">
        <div class="col-12 formgrid grid">
            <div :class="'field xl:col-6 lg:col-6 mt-3 md:col-12'">
                <Toolbar>
                    <template #start>
                        <Button :disabled="workflows.length == 0 ? true : false" @click="workflows_dialog = true" v-tooltip.bottom="'Workflows anzeigen'" label="Workflows anzeigen" :badge="String(workflows.length)" icon="pi pi-sitemap" class="mr-2" />
                        <Button :disabled="!pdf_url ? true : false" @click="pdf_dialog = true" v-tooltip.bottom="'Workflows anzeigen'" label="PDF anzeigen" icon="pi pi-file-pdf" class="mr-2" />
                    </template>
                </Toolbar>
                <Accordion v-if="active_workflow" class="accordion-custom mt-3 shadow-1" :activeIndex="0">
                    <AccordionTab>
                        <template #header>
                            <span class="mr-2" style="font-size: 130%;">Rechnungsdaten</span>
                            <Avatar :label="String(4)" class="mr-2" shape="circle" />
                        </template>
                        <div class="col-12 formgrid grid">
                            <div class="field xl:col-6 lg:col-6 p-1 md:col-6">
                                <span class="font-bold">Lieferant</span>
                            </div>
                            <div class="field xl:col-6 lg:col-6 p-1 md:col-6">
                                <span>{{supplier_name}}</span>
                            </div>
                            <div class="field xl:col-6 lg:col-6 p-1 md:col-6">
                                <span class="font-bold">Rechnungsdatum</span>
                            </div>
                            <div class="field xl:col-6 lg:col-6 p-1 md:col-6">
                                <span>{{invoice_date}}</span>
                            </div>
                            <div class="field xl:col-6 lg:col-6 p-1 md:col-6">
                                <span class="font-bold">Fälligkeitsdatum</span>
                            </div>
                            <div class="field xl:col-6 lg:col-6 p-1 md:col-6">
                                <span>{{invoice_due_date}}</span>
                            </div>
                            <div class="field xl:col-6 lg:col-6 p-1 md:col-6">
                                <span class="font-bold">Rechnungsnummer</span>
                            </div>
                            <div class="field xl:col-6 lg:col-6 p-1 md:col-6">
                                <span>{{invoice_number}}</span>
                            </div>
                            <div class="field xl:col-6 lg:col-6 p-1 md:col-6">
                                <span class="font-bold">Rechnungsbetrag</span>
                            </div>
                            <div class="field xl:col-6 lg:col-6 p-1 md:col-6">
                                <span class="font-bold text-red-600">{{invoice_amount}}</span>
                            </div>
                        </div>
                    </AccordionTab>
                </Accordion>
                <Accordion class="accordion-custom mt-3 shadow-1">
                    <AccordionTab>
                        <template #header>
                            <span class="mr-2" style="font-size: 130%;">Kommentare</span>
                            <Avatar :label="String(1)" class="mr-2 bg-red-100" shape="circle" />
                        </template>
                        Hello
                    </AccordionTab>
                </Accordion>
                <Accordion class="accordion-custom mt-3 shadow-1" :activeIndex="0">
                    <AccordionTab>
                        <template #header>
                            <span class="mr-2" style="font-size: 130%;">Freigabe</span>
                        </template>
                        <ToggleButton @click="click_approval()" v-model="check_approval" onLabel="Freigeben" offLabel="Freigeben" onIcon="pi pi-check-circle" offIcon="pi pi-circle" class="w-9rem mr-2" :class="check_approval == true ? 'bg-green-700' : ''" />
                        <ToggleButton @click="click_refusal()" v-model="check_refusal" onLabel="Ablehnen" offLabel="Ablehnen" onIcon="pi pi-times-circle" offIcon="pi pi-circle" class="w-9rem" :class="check_refusal == true ? 'bg-red-700' : ''" />
                        <div v-if="check_approval" class="mt-3">
                            <div><span class="font-bold mr-1">Bemerkung</span><small>(Optional)</small></div>
                            <Textarea v-model="comment" class="w-full"></Textarea>
                        </div>
                        <div v-if="check_refusal" class="mt-3">
                            <div><span class="font-bold mr-1">Begründung</span><small class="text-red-700">(Plichtfeld)</small></div>
                            <Textarea v-model="comment" class="w-full"></Textarea>
                        </div>
                        <div v-if="check_approval || check_refusal">
                            <Button :disabled="check_refusal && !comment ? true : false" icon="pi pi-save" label="Speichern" class="mt-3 w-auto mr-2 mb-1 border-green-800 bg-green-700 text-white" />
                        </div>
                    </AccordionTab>
                </Accordion>
            </div>
        </div>
    </div>
    <Sidebar v-model:visible="workflows_dialog" position="left" style="width: 80rem">
        <div class="col-12 formgrid grid">
            <DataTable :value="workflows" :rows="50" responsiveLayout="scroll" :rowHover="true" class="rounded mt-2">
                <Column header="Belege">
                    <template #body="slotProps">
                        <div class="mt-2">
                            <Chip class="pl-0 pr-3 mr-2">
                                <span class="bg-primary border-circle w-2rem h-2rem flex align-items-center justify-content-center"><i class="pi pi-file" /></span>
                                <span class="ml-2 font-medium">{{slotProps.data.meta.invoice_number}}</span>
                            </Chip>
                            <span class="font-bold mr-4 white-space-nowrap">{{slotProps.data.meta.supplier_name}}</span>
                            <Badge class="mr-2 mb-1 bg-primary text-white hidden" :value="slotProps.data.meta.invoice_number" v-tooltip.top="'Rechnungsnummer'"></Badge>
                            <Badge class="mr-2 mb-1 bg-primary text-white" v-if="slotProps.data.meta.invoice_amount" value="Rechnung" v-tooltip.top="'Belegtyp: Rechnung'"></Badge>
                            <Badge class="mr-2 mb-1 bg-red-500 text-white" v-if="slotProps.data.meta.invoice_amount" :value="slotProps.data.meta.invoice_currency + ' ' + slotProps.data.meta.invoice_amount" v-tooltip.top="'Rechnungsbetrag'"></Badge>
                            <Badge class="mr-2 mb-1 bg-gray-100 text-black-alpha-50" v-if="slotProps.data.meta.email_from" :value="slotProps.data.meta.email_from" v-tooltip.top="'Absender E-Mail'"></Badge>
                        </div>
                    </template>
                </Column>
                <Column field="email_date" header="Eingang" sortable>
                    <template #body="slotProps">
                        {{swiss_date(slotProps.data.meta.email_date)}}
                    </template>
                </Column>
                <Column>
                    <template #body="slotProps">
                        <Button icon="pi pi-folder-open" rounded v-tooltip.top="'Workflow anzeigen'" @click="load_workflow(slotProps.data)" />
                    </template>
                </Column>
            </DataTable>
        </div>
    </Sidebar>
    <Sidebar v-model:visible="pdf_dialog" position="right" style="width: 80rem">
        <iframe v-if="pdf_url" :src="pdf_url" style="width: 100%;height: 100%; border: none;">
            Oops! an error has occurred.
        </iframe>
    </Sidebar>
</template>