<script setup>
import { ref, onMounted, watch, defineProps, reactive} from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
//import PDFViewer from './components/pdf-viewer.vue'
import PDFEmbed from './submodules/pdf-embed.vue'
import { useAuthStore } from '@/core/store/AuthStore'

////////////
// System //
////////////

const auth = useAuthStore()

//////////////////
// Data Sources //
//////////////////

//Workflow
const workflow = ref({})
const users = ref([])
const selected_users = ref([])
const approval_users = ref([])
const workflow_ready = ref(false)
const change_logs = ref([])
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
const edit_workflow = ref({})
const workflow_status = ref(false)
const save_changes = ref(0)
const pdf_size = ref(6)
const workflow_size = ref(6)

// Base Data
const countries = ref([])
const currencies = ref([])
const languages = ref([])
const taxes = ref([])
const accounts = ref([])
const bank_accounts = ref([])
const conversion_rates = ref([])

// Email
const pdf_url = ref()
const email_html = ref()
const email_subject = ref()
const email_from = ref()
const email_date = ref()
const file_name = ref()

const props = defineProps({
  workflow_id: { type: String }
})

// on page load...
onMounted(() => {
    get_base_data()
})

const get_base_data = async () => {
    loader.value = true
    let payload = {"workflow_id": props.workflow_id}
    await Promise.all([
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/senler/get-workflow", payload),
        axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/senler/get-taxes", payload),
        axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/senler/get-accounts", payload),
        axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/senler/get-bank-accounts", payload),
        axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/senler/get-currencies", payload),
        axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/senler/get-countries", payload),
        axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/senler/get-languages", payload),
        axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/senler/get-users", payload)
    ])
    .then(async([workflow_response, taxes_response, accounts_response, bank_accounts_response, currencies_response, countries_response, languages_response, users_response]) => {
        workflow.value = workflow_response.data
        taxes.value = taxes_response.data
        accounts.value = accounts_response.data
        bank_accounts.value = bank_accounts_response.data
        currencies.value = currencies_response.data
        countries.value = countries_response.data
        languages.value = languages_response.data
        users.value = users_response.data
        parse_workflow_response(workflow_response.data).then(() => {
            workflow_ready.value = true
            loader.value = false
        })
    })
}

const parse_workflow_response = (response) => {
    console.log("workflow", workflow.value)
    change_logs.value = workflow.value.change_log
    edit_workflow.value = JSON.stringify(response)
    // wip: need to proxy pdf url
    pdf_url.value = response.meta.download_url
    // Supplier
    supplier_bexio_id.value = response.meta.supplier_bexio_id
    supplier_name.value = response.meta.supplier_name
    supplier_address.value = response.meta.supplier_address
    supplier_postcode.value = response.meta.supplier_postcode
    supplier_city.value = response.meta.supplier_city
    supplier_country.value = response.meta.supplier_country
    supplier_email.value = response.meta.supplier_email
    supplier_phone.value = response.meta.supplier_phone
    supplier_tax_id.value = response.meta.supplier_tax_id
    // Invoice
    if(response.meta.invoice_validation){
        invoice_validation.value = response.meta.invoice_validation
    }
    invoice_account.value = response.meta.invoice_account
    invoice_tax.value = response.meta.invoice_tax
    invoice_number_external.value = response.meta.invoice_number
    invoice_date.value = response.meta.invoice_date
    invoice_due_date.value = response.meta.invoice_due_date
    if(response.meta.invoice_brutto){invoice_brutto.value = Number(response.meta.invoice_brutto)}else{invoice_brutto.value = Number(response.meta.amount)}
    if(response.meta.invoice_type){invoice_type.value = response.meta.invoice_type}else{invoice_type.value = "PAYMENT"}
    if(response.meta.invoice_title){invoice_title.value = response.meta.invoice_title}else{invoice_title.value = " "}
    if(response.meta.invoice_line_items){custom_positions.value = response.meta.invoice_line_items}
    if(response.meta.invoice_discounts){custom_discount_positions.value = response.meta.invoice_discounts}
    // Invoice - parse currency
    if(response.meta.invoice_currency){invoice_currency.value = response.meta.invoice_currency}
    if(response.meta.payment_currency){
        payment_currency.value = response.meta.payment_currency
    } else {
        payment_currency.value = invoice_currency.value
    }
    // Invoice - check execution date
    if(response.meta.payment_execution_date){
        payment_execution_date.value = response.meta.payment_execution_date
    } else {
        if (response.meta.execution_date){
            payment_execution_date.value = response.meta.execution_date
        } else {
            let date = new Date()
            payment_execution_date.value = response.meta.invoice_due_date
        }
    }
    // Payment
    if(response.meta.payment_validation){
        payment_validation.value = response.meta.payment_validation
    }
    payment_bank_account.value = response.meta.payment_bank_account
    payment_booking_text.value = response.meta.payment_booking_text
    if(response.meta.payment_amount){payment_amount.value = Number(response.meta.payment_amount)}else{payment_amount.value = Number(response.meta.amount)}
    if(response.meta.payment_iban){payment_iban.value = response.meta.payment_iban}else{payment_iban.value = response.meta.supplier_iban}
    if(response.meta.payment_reference_number){payment_reference_number.value = response.meta.payment_reference_number}else{payment_reference_number.value = response.meta.invoice_reference}
    if(response.meta.payment_message){payment_message.value = response.meta.payment_message}else{payment_message.value = response.meta.invoice_number}
    if(response.meta.payment_name){payment_name.value = response.meta.payment_name}
    if(response.meta.payment_address){payment_address.value = response.meta.payment_address}
    if(response.meta.payment_postcode){payment_postcode.value = response.meta.payment_postcode}
    if(response.meta.payment_city){payment_city.value = response.meta.payment_city}
    if(response.meta.payment_country){payment_country.value = response.meta.payment_country}
    if(response.meta.approval_users){approval_users.value = response.meta.approval_users}
    email_html.value = response.meta.email_html
    email_subject.value = response.meta.email_subject
    email_from.value = response.meta.email_from
    email_date.value = response.meta.email_date
    file_name.value = response.meta.file_name
    swiss_qr_code.value = response.meta.swiss_qr_code
    if (swiss_qr_code.value) {
        payment_type.value = "QR"
    } else {
        payment_type.value = "IBAN"
    }
    // Payment - IBAN Check
    if (payment_iban.value) {
        check_iban()
    }
    return new Promise((resolve) => {
        resolve()
    })
}

const save_workflow = () => {
    let payload = {
        "workflow_id": props.workflow_id,
        "editor": auth.userdata.user_email,
        "meta": {
            //Supplier
            "supplier_bexio_id": supplier_bexio_id.value,
            "supplier_name": supplier_name.value,
            "supplier_address": supplier_address.value,
            "supplier_postcode": supplier_postcode.value,
            "supplier_city": supplier_city.value,
            "supplier_country": supplier_country.value,
            "supplier_email": supplier_email.value,
            "supplier_phone": supplier_phone.value,
            "supplier_iban": payment_iban.value,
            //Invoice
            "invoice_amount": invoice_brutto.value,
            "invoice_currency": invoice_currency.value,
            "invoice_tax": invoice_tax.value,
            "invoice_tax_id": get_tax_id(invoice_tax.value),
            "invoice_account": invoice_account.value,
            "invoice_account_id": get_account_id(invoice_account.value),
            "invoice_number": invoice_number_external.value,
            "invoice_date": invoice_date.value,
            "invoice_due_date": invoice_due_date.value,
            "invoice_type": invoice_type.value,
            "invoice_title": invoice_title.value,
            "invoice_validation": invoice_validation.value,
            "invoice_line_items": custom_positions.value,
            "invoice_discounts": custom_discount_positions.value,
            //Payment
            "payment_iban": payment_iban.value,
            "payment_execution_date": payment_execution_date.value,
            "payment_reference_number": payment_reference_number.value,
            "payment_bank_account": payment_bank_account.value,
            "payment_amount": payment_amount.value,
            "payment_currency": invoice_currency.value,
            "payment_booking_text": payment_booking_text.value,
            "payment_message": payment_message.value,
            "payment_type": payment_type.value,
            "payment_name": payment_name.value,
            "payment_address": payment_address.value,
            "payment_postcode": payment_postcode.value,
            "payment_city": payment_city.value,
            "payment_country": payment_country.value,
            "payment_validation": payment_validation.value,
            //Approval
            "approval_users": approval_users.value
        }
    }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/senler/save-workflow", payload)
        .then(response => {
            toast.add({severity: 'success', summary: 'Rechnung gespeichert', detail: 'Rechnung wurde erfolgreich gespeichert', life: 3000
        })
    })
}

const create_payment = () => {
    save_workflow()
    let line_items = []
    let discounts = []
    console.log("debug_payment", custom_positions.value)
    if (custom_discount_positions.value.length > 0){
        let position = 1
        for (let custom_discount_position of custom_discount_positions.value) {
            discounts.push({
                "position": position,
                "amount": custom_discount_position.amount
            })
            position++
        }
    }
    if (custom_positions.value.length > 0){
        let position = 0
        for (let custom_position of custom_positions.value) {
            line_items.push({
                "position": position,
                "title": custom_position.title,
                "tax_id": custom_position.invoice_tax.id,
                "amount": custom_position.amount,
                "booking_account_id": custom_position.invoice_account.id
            })
            position++
        }
    }
    else
    {
        line_items = [{
            "position": 0,
            "title": supplier_name.value + " " + invoice_number_external.value,
            "tax_id": get_tax_id(invoice_tax.value),
            "amount": invoice_brutto.value,
            "booking_account_id": get_account_id(invoice_account.value)
        }]
    }
    let payload = {
        "workflow_id": props.workflow_id,
        "supplier_id": supplier_bexio_id.value,
        "vendor_ref": invoice_number_external.value,
        "title": invoice_title.value,
        "contact_partner_id": 647,
        "bill_date": swiss_date_to_iso(invoice_date.value),
        "due_date": swiss_date_to_iso(invoice_due_date.value),
        "amount_man": payment_amount.value,
        "amount_calc": payment_amount.value,
        "manual_amount": true,
        "currency_code": invoice_currency.value,
        "exchange_rate": conversion_rate.value,
        "base_currency_amount": payment_amount.value,
        "item_net": false,
        //"qr_bill_information": "",
        "address": {
            //"firstname_suffix": "",
            "lastname_company": supplier_name.value,
            "address_line": supplier_address.value,
            "postcode": supplier_postcode.value,
            "city": supplier_city.value,
            "country_code": get_country_code(supplier_country.value),
            "type": "COMPANY"
        },
        "line_items": line_items,
        "discounts": discounts,
        "payment": {
            "type": payment_type.value,
            "bank_account_id": payment_bank_account.value,
            "fee": "BY_SENDER", // build in fee
            "execution_date": swiss_date_to_iso(payment_execution_date.value),
            "exchange_rate": conversion_rate.value, // build in exchange rate
            "amount": payment_amount.value,
            "iban": payment_iban.value.replace(" ", ""),
            "name": payment_name.value,
            "address": payment_address.value,
            "postcode": payment_postcode.value,
            "city": payment_city.value,
            "country_code": get_country_code(payment_country.value),
            "message": payment_message.value,
            "booking_text": payment_booking_text.value,
            "salary_payment": false,
            "reference_no": payment_reference_number.value,
            "note": payment_message.value
        }
    }
    if (workflow.value["meta"]["bexio_file_id"]){
        payload["attachment_ids"] = [workflow.value["meta"]["bexio_file_id"]]
    }
    console.log("payment_payload", payload)
    let matching_payload = {
        "supplier_id": supplier_bexio_id.value,
        "supplier_phone": supplier_phone.value,
        "supplier_email": supplier_email.value,
        "iban": payment_iban.value,
        "bank_account_id": payment_bank_account.value,
        "invoice_account_id": invoice_account.value,
        "currency": invoice_currency.value,
        "invoice_tax_id": invoice_tax.value,
        "note": payment_message.value,
        "booking_text": payment_booking_text.value,
        "supplier_tax_id": supplier_tax_id.value
    }
    console.log("matching_payload", matching_payload)
    console.log("positions", invoice_tax.value, invoice_account.value)
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/senler/create-purchase-bill", payload)
        .then(response => {
            console.log("create_payment", response.data)
            if (response.data.status == "OK") {
                axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/senler/create-matching", matching_payload)
                    .then(response => {
                        console.log("match_payment", response.data)
                        if (response.data.status == "OK") {
                            save_workflow()
                        }
                })
                toast.add({severity: 'success', summary: 'Rechnung erstellt', detail: 'Rechnung wurde erfolgreich erstellt', life: 3000})
            }
    })
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////// Supplier Section ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const supplier_name = ref()
const supplier_name_status = ref(false)
const supplier_address = ref()
const supplier_address_status = ref(false)
const supplier_postcode = ref()
const supplier_postcode_status = ref(false)
const supplier_city = ref()
const supplier_city_status = ref(false)
const supplier_country = ref()
const supplier_country_status = ref(false)
const supplier_language = ref()
const supplier_email = ref()
const supplier_phone = ref()
const supplier_status_field = ref()
const supplier_status_counter = ref(1)
const supplier_status = ref(false)
const supplier_tax_id = ref()
const newSupplierDialog = ref(false)
const searchSupplierDialog = ref(false)
const supplier_bexio_id = ref()
const bexio_search_string = ref()
const bexio_search_results = ref([])
const bexio_search_loader = ref(false)
const create_supplier_button_check = ref(false)
const loader_supplier = ref(false)
const supplier_exists = ref(false)

const validation_supplier = ref({
    supplier_name: 'error',
    supplier_address: 'error',
    supplier_postcode: 'error',
    supplier_city: 'error',
    supplier_country: 'error',
    supplier_language: 'error',
    supplier_email: 'error',
    supplier_phone: 'error',
    supplier_tax_id: 'error'
})

const supplier_status_check = () => {
    loader_supplier.value = true
    if (supplier_bexio_id.value) {
        let payload = {"contact_id": supplier_bexio_id.value}
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/senler/get-contact", payload)
        .then(response => {
            supplier_bexio_id.value = response.data.id
            supplier_name.value = response.data.name
            supplier_address.value = response.data.address
            supplier_postcode.value = response.data.postcode
            supplier_city.value = response.data.city
            supplier_country.value = response.data.country_id
            supplier_language.value = response.data.language_id
            supplier_email.value = response.data.email
            supplier_phone.value = response.data.phone
            supplier_status_field.value = "<div class='font-bold'>" + supplier_name.value + " (" + response.data.nr + ")" + "</div>" + supplier_address.value + ", " + supplier_postcode.value + " " + supplier_city.value
            supplier_status_counter.value = 0
            supplier_status.value = true
            loader_supplier.value = false
        })
    } else {
        supplier_bexio_id.value = ""
        supplier_status_field.value = "Kein Lieferant aus Bexio ausgewählt"
        supplier_status_counter.value = 1
        supplier_status.value = false
        loader_supplier.value = false
    }
}

watch ([supplier_bexio_id, supplier_name, supplier_address, supplier_postcode, supplier_city, supplier_country, supplier_language, supplier_email], (currentValue, oldValue) => {
    supplier_status_check()
})

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////// Invoice Section /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Variables
const invoice_currency = ref()
const invoice_currency_label = ref("")
const invoice_brutto = ref()
const invoice_tax = ref()
const invoice_tax_id = ref()
const invoice_account = ref()
const invoice_account_id = ref()
const invoice_title = ref()
const invoice_number_external = ref()
const invoice_date = ref()
const invoice_due_date = ref()
const conversion_rate = ref(1)
const converted_chf = ref()
const custom_positions = ref([])
const custom_positions_total = ref(0)
const custom_positions_difference = ref(0)
const last_custom_position_title = ref()
const last_custom_position_tax = ref()
const last_custom_position_account = ref()
const invoice_position_mode = ref(false)
const invoice_status_counter = ref(0)
const custom_discount_positions = ref([])
const custom_discount_positions_total = ref(0)
const invoice_discount_position_mode = ref(false)
const invoice_conversion_mode = ref(false)
const conversion_type = ref()
const conversion_options = ref(['Tageskurs', 'Monatsmittelkurs'])
const conversion_month = ref()
const conversion_day = ref()
const invoice_status = ref(false)
const invoice_title_status = ref(false)

// UI
const loader_invoice = ref(false)
const supportFormDialog = ref(false)
const conversion_fire_checker = ref()

// Error handling / Checkers
const invoice_validation = ref({
    invoice_currency: 'error',
    invoice_brutto: 'error',
    invoice_tax: 'error',
    invoice_account: 'error',
    invoice_date: 'error',
    invoice_due_date: 'error',
    invoice_title: 'warn',
    invoice_number_external: 'error',
    custom_position_tax: 'error',
    custom_position_title: 'error',
    custom_position_account: 'error',
    custom_position_amount: 'error',
    custom_positions_total: 'error',
    conversion_rate: 'error',
    custom_discount_position_title: 'error',
    custom_discount_position_amount: 'error'
})

const invoice_status_check = () => {
    loader_invoice.value = true
    if(invoice_date.value && String(invoice_date.value).match(/^\d{2}\.\d{2}\.\d{4}$/) !== null && invoice_date.value.length == 10 && invoice_validation.value.invoice_date != 'warn'){
        invoice_validation.value.invoice_date = 'success'
    }else{
        if (invoice_date.value.toString().substring(25, 28) == "GMT"){
            let date = new Date(invoice_date.value)
            invoice_date.value = date.getDate().toString().padStart(2,'0') + "." + (date.getMonth() + 1).toString().padStart(2,'0') + "." + date.getFullYear()
            conversion_day.value = invoice_date.value
            conversion_month.value = (date.getMonth() + 1).toString().padStart(2,'0') + "." + date.getFullYear()
        }else{
            invoice_validation.value.invoice_date = 'error'
        }
    }
    if(invoice_due_date.value && String(invoice_due_date.value).match(/^\d{2}\.\d{2}\.\d{4}$/) !== null && invoice_due_date.value.length == 10 && invoice_validation.value.invoice_due_date != 'warn'){
        invoice_validation.value.invoice_due_date = 'success'
    }else{
        if (invoice_due_date.value.toString().substring(25, 28) == "GMT"){
            let date = new Date(invoice_due_date.value)
            invoice_due_date.value = date.getDate().toString().padStart(2,'0') + "." + (date.getMonth() + 1).toString().padStart(2,'0') + "." + date.getFullYear()
        }else{
            invoice_validation.value.invoice_due_date = 'error'
        }
    }
    if(invoice_brutto.value > 0 && invoice_validation.value.invoice_brutto != 'warn'){
        invoice_validation.value.invoice_brutto = 'success'
    }else{
        invoice_validation.value.invoice_brutto = 'error'
    }
    if(typeof invoice_tax.value === 'object' && invoice_tax.value){
        invoice_tax.value = invoice_tax.value.name
        invoice_tax_id.value = invoice_tax.value.id
    }
    if(typeof invoice_account.value === 'object' && invoice_account.value){
        invoice_account.value = invoice_account.value.name
        invoice_account_id.value = invoice_account.value.id
    }
    if(invoice_tax.value && invoice_validation.value.invoice_tax != 'warn' || invoice_tax.value){
        invoice_validation.value.invoice_tax = 'success'
    }
    if(invoice_account.value && invoice_validation.value.invoice_account != 'warn'){
        invoice_validation.value.invoice_account = 'success'
    }
    if(!invoice_tax.value){
        invoice_validation.value.invoice_tax = 'error'
    }
    if(!invoice_account.value){
        invoice_validation.value.invoice_account = 'error'
    }
    if(invoice_title.value && invoice_validation.value.invoice_title != 'warn'){
        invoice_validation.value.invoice_title = 'success'
    }else{
        invoice_validation.value.invoice_title = 'warn'
    }
    if(invoice_number_external.value && invoice_validation.value.invoice_number_external != 'warn'){
        invoice_validation.value.invoice_number_external = 'success'
    }else{
        invoice_validation.value.invoice_number_external = 'error'
    }
    if(typeof invoice_currency.value === 'object'){
        invoice_currency.value = invoice_currency.value.name
        invoice_status_check()
    }
    if(invoice_currency.value && check_if_currency_exists(invoice_currency.value) && invoice_validation.value.invoice_currency != 'warn'){
        invoice_validation.value.invoice_currency = 'success'
        if(invoice_currency.value != 'CHF'){
            invoice_conversion_mode.value = true
            if(conversion_rate.value && invoice_validation.value.conversion_rate != 'warn'){
                invoice_validation.value.conversion_rate = 'success'
            }
        }else{
            invoice_conversion_mode.value = false
        }
    }
    // Validate custom positions
    if (custom_positions.value.length > 0) {
        invoice_position_mode.value = true
        custom_positions_total.value = 0
        invoice_validation.value.custom_position_tax = 'success'
        invoice_validation.value.custom_position_account = 'success'
        invoice_validation.value.custom_position_title = 'success'
        invoice_validation.value.custom_position_amount = 'success'
        invoice_validation.value.custom_positions_total = 'success'
        for (let custom_position of custom_positions.value) {
            custom_positions_total.value = Number(custom_positions_total.value) + Number(custom_position.amount)
            custom_positions_difference.value = Number(invoice_brutto.value) - Number(custom_positions_total.value)
            if(!custom_position.title){ invoice_validation.value.custom_position_title = 'warn'}else{last_custom_position_title.value = custom_position.title}
            if(!custom_position.invoice_account){ invoice_validation.value.custom_position_account = 'error'}else{last_custom_position_account.value = custom_position.invoice_account}
            if(!custom_position.invoice_tax){ invoice_validation.value.custom_position_tax = 'error'}else{last_custom_position_tax.value = custom_position.invoice_tax} 
            if(!custom_position.amount){ invoice_validation.value.custom_position_amount = 'error'}
        }
        if (custom_positions_total.value == Number(invoice_brutto.value)){
            invoice_validation.value.custom_positions_total = 'warn'
        }
    }

    // Validate custom discount positions
    if (custom_discount_positions.value.length > 0) {
        invoice_discount_position_mode.value = true
        custom_discount_positions_total.value = 0
        invoice_validation.value.custom_discount_position_title = 'success'
        invoice_validation.value.custom_discount_position_amount = 'success'
        for (let custom_discount_position of custom_discount_positions.value) {
            custom_discount_positions_total.value = custom_discount_positions_total.value + custom_discount_position.amount
            if(!custom_discount_position.title){ invoice_validation.value.custom_discount_position_title = 'error'}
            if(!custom_discount_position.amount || custom_discount_position.amount == 0){ invoice_validation.value.custom_discount_position_amount = 'error'}
        }
    }
    else
    {
        invoice_discount_position_mode.value = false
        custom_discount_positions_total.value = 0
    }

    // Validate conversion rates
    if(invoice_conversion_mode.value == true && conversion_type.value == 'daily' && !conversion_day.value) {
        conversion_day.value = invoice_date.value
    }
    if(invoice_conversion_mode.value == true && conversion_type.value == 'monthly' && !conversion_month.value) {
        conversion_month.value = invoice_date.value.toString().substring(3, 10)
    }
    if(!conversion_type.value){
        conversion_type.value = 'manual'
    }
    if(invoice_conversion_mode.value == true && conversion_type.value == 'manual' && conversion_rate.value){
            // Manual conversion
            converted_chf.value = swiss_rounding(payment_amount.value * conversion_rate.value)
    }
    if(invoice_conversion_mode.value == true && conversion_day.value && invoice_validation.value.invoice_date == 'success'){
        if(conversion_type.value == 'daily' && conversion_day.value != conversion_fire_checker.value){
            if (conversion_day.value.toString().substring(25, 28) == "GMT") {
                let date = new Date(conversion_day.value)
                conversion_day.value = date.getDate().toString().padStart(2,'0') + "." + (date.getMonth() + 1).toString().padStart(2,'0') + "." + date.getFullYear()
            }
            conversion_fire_checker.value = conversion_day.value
            let payload = {amount: payment_amount.value, date: swiss_date_to_iso(conversion_day.value), currency_from: invoice_currency.value, currency_to: "CHF"}
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/senler/convert-currency-daily", payload)
            .then(response => {
                if (response.data.status == "success") {
                    conversion_rate.value = response.data.rate
                    converted_chf.value = swiss_rounding(response.data.amount)
                }
            })
        }
        if(conversion_type.value == 'monthly' && conversion_month.value && conversion_month.value != conversion_fire_checker.value){
            if (conversion_month.value.toString().substring(25, 28) == "GMT") {
                let date = new Date(conversion_month.value)
                conversion_month.value = (date.getMonth() + 1).toString().padStart(2,'0') + "." + date.getFullYear()
            }
            conversion_fire_checker.value = conversion_month.value
            let payload = {amount: payment_amount.value, year: conversion_month.value.substring(3,7), month: conversion_month.value.substring(0,2), currency_from: invoice_currency.value}
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/senler/convert-currency-monthly", payload)
            .then(response => {
                if (response.data.status == "success") {
                    conversion_rate.value = response.data.rate
                    converted_chf.value = swiss_rounding(response.data.amount)
                }
            })
        }
    }

    // Count validation
    let counter = 0
    for (let key in invoice_validation.value) {
        if (invoice_validation.value[key] == 'error' && ['custom_position_tax', 'custom_position_account', 'custom_position_title', 'custom_position_amount'].includes(key) && invoice_position_mode.value == true) {
            counter++
        }
        if (invoice_validation.value[key] == 'error' && ['invoice_tax', 'invoice_account'].includes(key) && invoice_position_mode.value == false) {
            counter++
        }
        if (invoice_validation.value[key] == 'error' && ['conversion_rate'].includes(key) && invoice_conversion_mode.value == true) {
            counter++
        }
        if (['invoice_currency', 'invoice_brutto', 'invoice_number_external', 'invoice_date', 'invoice_due_date'].includes(key) && invoice_validation.value[key] == 'error') {
            counter++
        }
    }
    console.log("invoice_validation", invoice_validation.value)
    console.log("payment_amount_math", invoice_brutto.value, custom_positions_difference.value, custom_discount_positions_total.value, invoice_brutto.value - custom_positions_difference.value - custom_discount_positions_total.value)
    payment_amount.value = invoice_brutto.value - custom_positions_difference.value - custom_discount_positions_total.value
    invoice_status_counter.value = counter
    if (counter == 0) {
        invoice_status.value = true
    } else {
        invoice_status.value = false
    }
    loader_invoice.value = false
}

const add_position = () => {
    if (custom_positions.value.length == 0) {
        custom_positions.value.push({"title": "Position 1", "amount": invoice_brutto.value, "invoice_account": invoice_account.value, "invoice_tax": invoice_tax.value})
    }
    else {
        custom_positions.value.push({"title": "Position " + String(custom_positions.value.length + 1), "amount": custom_positions_difference.value, "invoice_account": last_custom_position_account.value, "invoice_tax": last_custom_position_tax.value})
    }
    invoice_status_check()
}

const delete_position = (index) => {
    custom_positions.value.splice(index, 1)
    invoice_status_check()
}

const add_discount_position = (percent) => {
    let discount_title = ""
    let discount_percent = 0
    let discount_amount = 0
    if (percent == 0){
        discount_title = "Rabatt"
        discount_percent = 0
        discount_amount = (invoice_brutto.value / 100) * percent
    }
    if (percent == 2){
        discount_title = "Skonto 2%"
        discount_percent = 2
        discount_amount = (invoice_brutto.value / 100) * percent
    }
    if (percent == 3){
        discount_title = "Skonto 3%"
        discount_percent = 3
        discount_amount = (invoice_brutto.value / 100) * percent
    }
    if (percent == 5){
        discount_title = "Skonto 5%"
        discount_percent = 5
        discount_amount = (invoice_brutto.value / 100) * percent
    }
    custom_discount_positions.value.push({"title": discount_title, "percentage": discount_percent, "amount": swiss_rounding(discount_amount)})
    invoice_status_check()
}

const change_discount_position_values = (event, data) => {
    console.log("trigger update", event)
    if(data.field == "amount"){
        custom_discount_positions.value[data.index]["percentage"] = (event.value / invoice_brutto.value) * 100
        console.log("updating via amount", (event.value / invoice_brutto.value) * 100)
    }
    if(data.field == "percentage"){
        custom_discount_positions.value[data.index]["amount"] = swiss_rounding((invoice_brutto.value / 100) * event.value)
        console.log("updating via percentage", (invoice_brutto.value / 100) * event.value)
    }
    invoice_status_check()
}

const delete_discount_position = (index) => {
    custom_discount_positions.value.splice(index, 1)
    invoice_status_check()
}


const filteredAccounts = ref()
const search_account = (event) => {
    setTimeout(() => {
        if (!event.query.trim().length) {
            filteredAccounts.value = [...accounts.value];
        } else {
            filteredAccounts.value = accounts.value.filter((account) => {
                return account.name.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }
    }, 250);
}

const filteredTaxes = ref()
const search_tax = (event) => {
    setTimeout(() => {
        if (!event.query.trim().length) {
            filteredTaxes.value = [...taxes.value];
        } else {
            filteredTaxes.value = taxes.value.filter((account) => {
                return account.name.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }
    }, 250);
}

const filteredCurrencies = ref()
const search_currency = (event) => {
    setTimeout(() => {
        if (!event.query.trim().length) {
            filteredCurrencies.value = [...currencies.value];
        } else {
            filteredCurrencies.value = currencies.value.filter((currency) => {
                return currency.name.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }
    }, 250);
}

watch ([workflow_ready, invoice_validation, invoice_brutto, invoice_currency, invoice_tax, invoice_account, invoice_title, invoice_number_external, invoice_date, invoice_due_date, conversion_rate, conversion_day, conversion_month, conversion_type, custom_positions.value], (currentValue, oldValue) => {
    if (workflow_ready.value == true){
        invoice_status_check()
    }
})

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////// Payment Section /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Variables
const swiss_qr_code = ref()
const payment_iban = ref()
const payment_iban_checked_value = ref()
const iban_validation = ref({})
const iban_is_valid  = ref(false)
const iban_validation_message = ref()
const payment_execution_date = ref()
const payment_currency = ref()
const payment_reference_number = ref()
const payment_bank_account = ref()
const payment_amount = ref(0)
const payment_booking_text = ref()
const payment_booking_text_counter = ref(35)
const payment_message = ref()
const payment_message_counter = ref(140)
const payment_status = ref(false)
const payment_status_counter = ref(0)
const payment_type = ref("IBAN")
const invoice_type = ref()
const payment_name = ref()
const payment_address = ref()
const payment_postcode = ref()
const payment_city = ref()
const payment_country = ref()

// UI
const loader_payment = ref(false)

// Error handling / Checkers
const payment_validation = ref({
    payment_bank_account: 'error',
    payment_execution_date: 'error',
    payment_amount: 'error',
    payment_iban: 'error',
    payment_reference_number: 'error',
    payment_name: 'error',
    payment_address: 'error',
    payment_postcode: 'error',
    payment_city: 'error',
    payment_country: 'error'
})

const payment_status_check = () => {
    loader_payment.value = true
    if(payment_bank_account.value && payment_validation.value.payment_bank_account != 'warn'){
        payment_validation.value.payment_bank_account = 'success'
    }else{
        payment_validation.value.payment_bank_account = 'error'
    }
    if(payment_execution_date.value && String(payment_execution_date.value).match(/^\d{2}\.\d{2}\.\d{4}$/) !== null && payment_execution_date.value.length == 10 && payment_validation.value.payment_execution_date != 'warn'){
        payment_validation.value.payment_execution_date = 'success'
    }else{
        if (payment_execution_date.value.toString().substring(25, 28) == "GMT"){
            let date = new Date(payment_execution_date.value)
            payment_execution_date.value = date.getDate().toString().padStart(2,'0') + "." + (date.getMonth() + 1).toString().padStart(2,'0') + "." + date.getFullYear()
        }else{
            payment_validation.value.payment_execution_date = 'error'
        }
    }
    if(payment_iban.value && payment_validation.value.payment_iban != 'warn'){
        payment_validation.value.payment_iban = 'success'
    }else{
        payment_validation.value.payment_iban = 'error'
    }
    if(payment_amount.value && payment_validation.value.payment_amount != 'warn'){
        payment_validation.value.payment_amount = 'success'
    }else{
        payment_validation.value.payment_amount = 'error'
    }
    if(payment_type.value == "QR"){
        if(payment_reference_number.value && payment_validation.value.payment_reference_number != 'warn'){
            payment_validation.value.payment_reference_number = 'success'
        }else{
            payment_validation.value.payment_reference_number = 'error'
        }
    }else{
        payment_validation.value.payment_reference_number = 'success'
    }
    if(!payment_name.value){payment_name.value = supplier_name.value}
    if(!payment_address.value){payment_address.value = supplier_address.value}
    if(!payment_postcode.value){payment_postcode.value = supplier_postcode.value}
    if(!payment_city.value){payment_city.value = supplier_city.value}
    if(!payment_country.value){payment_country.value = get_country_id(supplier_country.value)}
    if(payment_name.value && payment_validation.value.payment_name != 'warn'){
        payment_validation.value.payment_name = 'success'
    }else{
        payment_validation.value.payment_name = 'error'
    }
    if(payment_address.value && payment_validation.value.payment_address != 'warn'){
        payment_validation.value.payment_address = 'success'
    }else{
        payment_validation.value.payment_address = 'error'
    }
    if(payment_postcode.value && payment_validation.value.payment_postcode != 'warn'){
        payment_validation.value.payment_postcode = 'success'
    }else{
        payment_validation.value.payment_postcode = 'error'
    }
    if(payment_city.value && payment_validation.value.payment_city != 'warn'){
        payment_validation.value.payment_city = 'success'
    }else{
        payment_validation.value.payment_city = 'error'
    }
    if(payment_country.value && payment_validation.value.payment_country != 'warn'){
        payment_validation.value.payment_country = 'success'
    }else{
        payment_validation.value.payment_country = 'error'
    }
    // Count validation
    let counter = 0
    for (let key in payment_validation.value) {
        if(payment_validation.value[key] == 'error'){
            counter += 1
        }
    }
    if (payment_iban_checked_value.value != payment_iban.value){
        check_iban()
    }
    payment_status_counter.value = counter
    if (counter == 0) {
        payment_status.value = true
    } else {
        payment_status.value = false
    }
    loader_payment.value = false
    console.log("payment_validation", payment_validation.value)
}

watch ([workflow_ready, payment_bank_account, payment_execution_date, payment_amount, payment_iban, payment_reference_number, payment_name, payment_address, payment_postcode, payment_city, payment_country], (currentValue, oldValue) => {
    if (workflow_ready.value == true){
        payment_status_check()
    }
})


const loader_iban = ref(false)

const check_iban = () => {
    loader_iban.value = true
    let payload = {
        "iban": payment_iban.value
    }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/senler/validate-iban", payload)
    .then(response => {
        iban_validation.value = response.data
        iban_is_valid.value = response.data.valid
        payment_iban_checked_value.value = payment_iban.value
        if (iban_is_valid.value == true){
            payment_iban.value = iban_validation.value["iban"]
            payment_iban_checked_value.value = iban_validation.value["iban"]
            iban_validation_message.value = "IBAN ist gültig"
            if (iban_validation.value["bank_name"]){
                iban_validation_message.value = iban_validation_message.value + " - " + iban_validation.value["bank_name"]
            }
            if (iban_validation.value["country"]){
                iban_validation_message.value = iban_validation_message.value + " - " + iban_validation.value["country"]
            }
        }
        loader_iban.value = false
        return response.data
    })
}

watch ([supplier_status, invoice_status, payment_status], (currentValue, oldValue) => {
    if (supplier_status.value && invoice_status.value && payment_status.value) {
        workflow_status.value = true
    } else {
        workflow_status.value = false
    }
})

const create_supplier_check = () => {
    supplier_name_status.value = !supplier_name.value ? false : true
    supplier_address_status.value = !supplier_address.value ? false : true
    supplier_postcode_status.value = !supplier_postcode.value ? false : true
    supplier_city_status.value = !supplier_city.value ? false : true
    supplier_country_status.value = !supplier_country.value ? false : true
    if (supplier_name_status.value && supplier_address_status.value && supplier_postcode_status.value && supplier_city_status.value && supplier_country_status.value) {
        create_supplier_button_check.value = true
    }
    else
    {
        create_supplier_button_check.value = false
    }
}

const create_bexio_supplier = () => {
    let payload = {
        "name": supplier_name.value,
        "address": supplier_address.value,
        "postcode": supplier_postcode.value,
        "city": supplier_city.value,
        "country": supplier_country.value,
        "language": supplier_language.value,
        "email": supplier_email.value,
        "phone": supplier_phone.value
    }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/senler/create-contact", payload)
    .then(response => {
        supplier_bexio_id.value = response.data.contact_id
        toast.add({severity: 'success', summary: 'Erfolg', detail: 'Lieferant wurde erfolgreich angelegt', life: 3000})
        newSupplierDialog.value = false
    })
}

const bexio_search = () => {
    bexio_search_loader.value = true
    let payload = {
        "search": bexio_search_string.value
    }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/senler/search-contacts", payload)
    .then(response => {
        console.log(response.data)
        if (response.data.length == 0) {
            bexio_search_results.value = []
        }
        else
        {
            bexio_search_results.value = response.data
        }
        bexio_search_loader.value = false
    })
}


const send_support_ticket = () => {
    let payload = {
        "workflow_id": support_details.value["workflow_id"],
        "message": support_details.value["details"],
        "email": support_details.value["customer_id"]
    }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/senler/support", payload)
    .then(response => {
        console.log(response.data)
        supportFormDialog.value = false
        toast.add({severity: 'success', summary: 'Erfolg', detail: 'Supportanfrage wurde erfolgreich versendet', life: 3000})
    })
}

const select_bexio_contact = (e) => {
    searchSupplierDialog.value = false
    newSupplierDialog.value = false
    confirm.require({
        header: 'Lieferant auswählen',
        message: 'Soll der Lieferant "' + e.data.name_1 + '" ausgewählt werden?',
        icon: 'pi pi-exclamation-triangle',
        rejectLabel: 'Nein',
        acceptLabel: 'Ja, ' + e.data.name_1 + ' wählen',
        acceptClass: 'p-button',
        accept: () => {
            supplier_bexio_id.value = e.data.id
            supplier_name.value = e.data.name_1
            supplier_address.value = e.data.address
            supplier_postcode.value = e.data.postcode
            supplier_city.value = e.data.city
            supplier_country.value = e.data.country_id
            supplier_language.value = e.data.language_id
            supplier_email.value = e.data.email
            supplier_phone.value = e.data.phone
            payment_name.value = e.data.name_1
            payment_address.value = e.data.address
            payment_postcode.value = e.data.postcode
            payment_city.value = e.data.city
            payment_country.value = e.data.country_id
            supplier_status_check()
        },
        reject: () => {
            return
        }
    })
}

const search_supplier = () => {
    bexio_search_string.value = supplier_name.value
    if(bexio_search_string.value) {
        bexio_search()
    }
    searchSupplierDialog.value = true
}

const new_supplier = () => {
    let payload = {
        "search": workflow.value.meta.supplier_name
    }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/senler/search-contacts", payload)
    .then(response => {
        if (response.data.length > 0) {
            supplier_exists.value = true
            bexio_search_results.value = response.data
        }
        supplier_name.value = workflow.value.meta.supplier_name
        supplier_address.value = workflow.value.meta.supplier_address
        supplier_postcode.value = workflow.value.meta.supplier_postcode
        supplier_city.value = workflow.value.meta.supplier_city
        supplier_country.value = get_country_id(workflow.value.meta.supplier_country)
        supplier_language.value = 1
        supplier_email.value = workflow.value.meta.supplier_email
        supplier_phone.value = workflow.value.meta.supplier_phone
        create_supplier_check()
        newSupplierDialog.value = true
    })
}

const send_approval_workflow = () => {
    if(selected_users.value.sort().join(',')=== approval_users.value.sort().join(',')){
        alert('Diese Rechnung wurde bereits an diese Benutzer gesendet!');
    } else {
        confirm.require({
            message: 'Freigabeanforderung an '+String(selected_users.value.length)+' Benutzer senden?\n (Bereits gesendete Anfragen werden nicht erneut gesendet)',
            header: 'Für Freigabe senden?',
            rejectLabel: 'Nein',
            acceptLabel: 'Ja, für Freigabe senden',
            acceptClass: 'p-button-info',
            icon: 'pi pi-check-square',
            accept: () => {
                approval_users.value = selected_users.value
                save_workflow()
            }
        })
    }
}

///////////////
// Interface //
///////////////

const emits = defineEmits(['editorCallback'])

const close_tab = () => {
    confirm.require({
        message: 'Sind Sie sicher, dass Sie diese Rechnung schliessen wollen? Alle nicht gespeicherten Änderungen gehen verloren.',
        header: 'Rechnung schliessen',
        rejectLabel: 'Nein',
        acceptLabel: 'Ja, Rechnung schliessen',
        acceptClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            emits('editorCallback',
                {
                    "action": "close_tab",
                    "workflow_id": props.workflow_id
                }
            )
        }
    })
}

watch (payment_message, () => {
    payment_message_counter.value = characters_left(payment_message.value, 140)
    if (payment_message_counter.value <= 0) {
        payment_message.value = payment_message.value.substring(0, 140)
    }
})

watch (payment_booking_text, () => {
    payment_booking_text_counter.value = characters_left(payment_booking_text.value, 35)
    if (payment_booking_text_counter.value <= 0) {
        payment_booking_text.value = payment_booking_text.value.substring(0, 35)
    }
})

////////////////
// Formatting //
////////////////

const swiss_date = (date) => {
    return new Date(date).toLocaleDateString('de-CH', { year: 'numeric', month: '2-digit', day: '2-digit' })
}

const swiss_date_time = (date) => {
    return new Date(date).toLocaleDateString('de-CH', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })
}

const characters_left = (field, limit) => {
    let chars = field.length
    return limit - chars
}

const get_country_id = (country) => {
    let id = ""
    countries.value.forEach(element => {
        if (element.name == country) {
            id = element.id
        }
    })
    return id
}

const get_country_code = (country_id) => {
    let code = ""
    countries.value.forEach(element => {
        if (element.id == country_id) {
            code = element.iso_3166_alpha2
        }
    })
    return code
}

const get_account_id = (account_name) => {
    let id = ""
    accounts.value.forEach(element => {
        if (element.name == account_name) {
            id = element.id
        }
    })
    return id
}

const get_tax_id = (tax_name) => {
    let id = ""
    taxes.value.forEach(element => {
        if (element.name == tax_name) {
            id = element.id
        }
    })
    return id
}

const get_currency_code = (currency_id) => {
    let code = ""
    currencies.value.forEach(element => {
        if (element.id == currency_id) {
            code = element.name
        }
    })
    return code
}

const check_if_currency_exists = (currency_code) => {
    let checker = false
    currencies.value.forEach(element => {
        if (element.name == currency_code) {
            checker = true
        }
    })
    return checker
}

const swiss_date_to_iso = (date) => {
    return date.split('.').reverse().join('-')
}

//function to round to the nearest 0.05
const swiss_rounding = (value) => {
    return Math.round(value * 20) / 20
}

const support_details = ref({
    workflow_id: null,
    customer_id: null,
    details: null,
})

const support_form = (workflow_id) => {
    support_details.value["workflow_id"] = workflow_id
    support_details.value["customer_id"] = auth.userdata.user_email
    support_details.value["details"] = null
    supportFormDialog.value = true
}

const help_menu = ref()
const toggle_help_menu = (event) => {
    help_menu.value.toggle(event);
}
const help_menu_items = ref([
    {
        items: [
            {
                label: 'Hilfe anfordern',
                icon: 'pi pi-comment',
                command: (e) => support_form(props.workflow_id)
            }
        ]
    }
])

const download_pdf = (url, label) => {
    axios.get(url, { responseType: 'blob' })
        .then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = label
        link.click()
        URL.revokeObjectURL(link.href)
        }).catch(console.error)
}

const open_pdf = () => {
    const newWindow = window.open('https://senler.snpy.ch/ext-pdf?token='+props.workflow_id, '_blank', 'width=600,height=800');
    if (newWindow) {
    newWindow.focus();
    }
}
</script>

<style scoped>
.p-scrollpanel-wrapper {
    z-index: auto !important;
}
.p-scrollpanel-bar {
    background: #3751ff !important;
}
.vue-pdf-embed > div {
  margin-bottom: 8px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}
.p-blockui {
    border-radius: 10px;
}
.p-confirm-dialog-message {
    white-space: pre-line;
}
</style>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <Toast />
    <ProgressSpinner v-if="loader || loader_invoice || loader_iban || loader_supplier || loader_payment" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <BlockUI :blocked="loader">
    <div class="col-12 formgrid grid">
        <div :class="'field xl:col-'+workflow_size+' lg:col-'+workflow_size+' mt-3 md:col-12'">
            <div class="field col-12 mt-2">
                <Toolbar>
                    <template #start>
                        <Button @click="save_workflow()" v-tooltip.bottom="'Rechnung speichern'" label="Rechnung speichern" class="mr-2 border-green-800 bg-green-700 text-white" icon="pi pi-save" />
                    </template>
                    <template #end>
                        <Button rounded size="small" @click="toggle_help_menu">
                            <span class="mr-2">Support</span>
                            <i class="pi pi-angle-down" />
                        </Button>
                        <Menu ref="help_menu" id="overlay_menu" :model="help_menu_items" :popup="true" />
                    </template>
                </Toolbar>
            </div>
            <div class="field col-12 mt-2">
                <BlockUI :blocked="loader_supplier">
                    <Accordion class="accordion-custom mt-3 shadow-1" :activeIndex="0">
                        <AccordionTab>
                            <template #header>
                                <span class="mr-2" style="font-size: 130%;">Lieferant</span>
                                <Avatar v-if="!supplier_status" :label="String(1)" class="mr-2 bg-red-100" shape="circle"  />
                                <Avatar v-if="supplier_status" icon="pi pi-check" class="mr-2 bg-green-100" shape="circle"  />
                            </template>
                            <div class="col-12 formgrid grid">
                                <div class="field col-12 mt-1">
                                    <div v-if="!supplier_status" class="p-2 border-round border-1 border-red-500 bg-red-100 font-bold w-full">{{ supplier_status_field }}</div>
                                    <div v-if="supplier_status" class="p-2 border-round border-1 border-green-500 bg-green-100 w-full" v-html="supplier_status_field"></div>
                            </div>
                                <div class="field col-12 mt-1">
                                    <Button @click="search_supplier" v-tooltip.bottom="'Lieferant in Bexio suchen...'" label="Lieferant suchen" class="mr-2 p-button" style="background-color: #0d2f3b; color: #bbdc00" icon="pi pi-search" />
                                    <Button @click="new_supplier" v-tooltip.bottom="'Neuen Lieferant in Bexio erfassen'" label="Neuen Lieferant erfassen" class="mr-2 p-button" icon="pi pi-plus" />
                                </div>
                            </div>
                        </AccordionTab>
                    </Accordion>
                </BlockUI>
            </div>
            <div class="field col-12 mt-3">
                <BlockUI :blocked="loader_invoice">
                    <Accordion class="accordion-custom mt-3 shadow-1" :activeIndex="0">
                        <AccordionTab>
                            <template #header>
                                <span class="mr-2" style="font-size: 130%;">Rechnungsdaten</span>
                                <Avatar v-if="invoice_status_counter > 0" :label="String(invoice_status_counter)" class="mr-2 bg-red-100" shape="circle" />
                                <Avatar v-if="invoice_status_counter == 0" icon="pi pi-check" class="mr-2 bg-green-100" shape="circle" />
                            </template>
                            <div class="grid">
                                <div class="field col-12 grid mt-3">
                                    <label for="invoice_number_external" class="col-12 mb-2 md:col-12 md:mb-2 lg:col-4 lg:mb-0 xl:col-4 xl:mb-0">Rechnungsnummer</label>
                                    <div class="col-12 md:col-12 lg:col-8 xl:col-8">
                                        <InputText id="invoice_number_external" v-model="invoice_number_external" :class="[invoice_validation.invoice_number_external == 'error' && 'border-red-500', invoice_validation.invoice_number_external == 'success' && 'border-green-500', invoice_validation.invoice_number_external == 'warn' && 'border-orange-500']" class="w-full" />
                                        <small v-if="invoice_validation.invoice_number_external != 'success'" class="p-error">Bitte eine Rechnungsnummer eingeben</small>
                                    </div>
                                </div>
                                <div class="field col-12 grid mt-3">
                                    <label for="invoice_date" class="col-12 mb-2 md:col-12 md:mb-2 lg:col-4 lg:mb-0 xl:col-4 xl:mb-0">Rechnungsdatum</label>
                                    <div class="col-12 md:col-12 lg:col-8 xl:col-8">
                                        <Calendar id="invoice_date" v-model="invoice_date" :manualInput="true" :showOnFocus="false" showIcon dateFormat="dd.mm.yy" :class="invoice_validation.invoice_date == 'error' ? 'p-invalid' : ''" :inputStyle="invoice_validation.invoice_date == 'success' ? {'border-color':'#22c55e'} : {'border-color':'#e24c4c'}" class="w-full" />
                                        <small v-if="invoice_validation.invoice_date != 'success'" class="p-error">Bitte ein Rechnungsdatum eingeben</small>
                                    </div>
                                </div>
                                <div class="field col-12 grid mt-3">
                                    <label for="invoice_due_date" class="col-12 mb-2 md:col-12 md:mb-2 lg:col-4 lg:mb-0 xl:col-4 xl:mb-0">Fälligkeitsdatum</label>
                                    <div class="col-12 md:col-12 lg:col-8 xl:col-8">
                                        <Calendar id="invoice_due_date" v-model="invoice_due_date" :manualInput="true" :showOnFocus="false" showIcon dateFormat="dd.mm.yy" :class="invoice_validation.invoice_due_date == 'error' ? 'p-invalid' : ''" :inputStyle="invoice_validation.invoice_due_date == 'success' ? {'border-color':'#22c55e'} : {'border-color':'#e24c4c'}" class="w-full" />
                                        <small v-if="invoice_validation.invoice_due_date != 'success'" class="p-error">Bitte ein Fälligkeitsdatum eingeben</small>
                                    </div>
                                </div>
                                <div class="field col-12 grid mt-3">
                                    <label for="invoice_currency" class="col-12 mb-2 md:col-12 md:mb-2 lg:col-4 lg:mb-0 xl:col-4 xl:mb-0">Währung</label>
                                    <div class="col-12 md:col-12 lg:col-8 xl:col-8">
                                        <AutoComplete id="invoice_currency" dropdown dropdownMode="blank" :forceSelection="false" :completeOnFocus="true" placeholder="Währung auswählen..." @complete="search_currency" v-model="invoice_currency" :suggestions="filteredCurrencies" optionLabel="name" :inputStyle="invoice_validation.invoice_currency == 'success' ? {'border-color':'#22c55e'} : {'border-color':'#e24c4c'}" class="w-full" />
                                        <small v-if="invoice_validation.invoice_currency != 'success'" class="p-error">Bitte eine Währung auswählen</small>
                                    </div>
                                </div>
                                <div class="field col-12 grid mt-3">
                                    <label for="brutto_betrag" class="col-12 mb-2 md:col-12 md:mb-2 lg:col-4 lg:mb-0 xl:col-4 xl:mb-0">Brutto-Betrag</label>
                                    <div class="col-12 md:col-12 lg:col-8 xl:col-8">
                                        <InputNumber placeholder="Brutto-Betrag" locale="de-CH" :minFractionDigits="2" :maxFractionDigits="2" id="brutto" v-model="invoice_brutto" :class="[invoice_validation.invoice_brutto == 'error' && 'border-red-500', invoice_validation.invoice_brutto == 'success' && 'border-green-500', invoice_validation.invoice_brutto == 'warn' && 'border-orange-500']" class="w-full" />
                                        <div v-if="invoice_validation.invoice_brutto != 'success'"><small class="p-error">Bitte geben Sie einen gültigen Betrag ein</small></div>
                                    </div>
                                </div>
                                <div v-if="custom_positions.length == 0"  class="field col-12 grid mt-3">
                                    <label for="invoice_account" class="col-12 mb-2 md:col-12 md:mb-2 lg:col-4 lg:mb-0 xl:col-4 xl:mb-0">Konto</label>
                                    <div class="col-12 md:col-12 lg:col-8 xl:col-8">
                                        <AutoComplete id="invoice_account" dropdown :forceSelection="true" :completeOnFocus="true" placeholder="Konto wählen..." @complete="search_account" v-model="invoice_account" :suggestions="filteredAccounts" optionLabel="name" :inputStyle="invoice_validation.invoice_account == 'success' ? {'border-color':'#22c55e'} : {'border-color':'#e24c4c'}" class="w-full" />
                                        <small v-if="invoice_validation.invoice_account != 'success'" class="p-error">Bitte ein Konto auswählen</small>
                                    </div>
                                </div>
                                <div v-if="custom_positions.length == 0"  class="field col-12 grid mt-3">
                                    <label for="invoice_tax" class="col-12 mb-2 md:col-12 md:mb-2 lg:col-4 lg:mb-0 xl:col-4 xl:mb-0">Steuersatz</label>
                                    <div class="col-12 md:col-12 lg:col-8 xl:col-8">
                                        <AutoComplete id="invoice_tax" dropdown :forceSelection="true" :completeOnFocus="true" placeholder="Steuersatz wählen..." @complete="search_tax" v-model="invoice_tax" :suggestions="filteredTaxes" optionLabel="name" :inputStyle="invoice_validation.invoice_tax == 'success' ? {'border-color':'#22c55e'} : {'border-color':'#e24c4c'}" class="w-full" />
                                        <small v-if="invoice_validation.invoice_tax != 'success'" class="p-error">Bitte einen Steuersatz auswählen</small>
                                    </div>
                                </div>
                                <div v-if="custom_positions.length > 0" class="field col-12 mt-1">
                                    <DataTable :value="custom_positions" :rowHover="true" class="p-datatable-sm" scrollable style="border: 1px solid #cbd5e1;">
                                        <Column field="title" header="Position" style="min-width: 200px; max-width: 250px;">
                                            <template #body="slotProps">
                                                <InputText @focus="$event.target.select()" v-model="slotProps.data.title" :class="[invoice_validation.custom_position_title == 'error' && 'border-red-500', invoice_validation.custom_position_title == 'success' && 'border-green-500', invoice_validation.custom_position_title == 'warn' && 'border-orange-500']" class="w-full" />
                                            </template>
                                        </Column>
                                        <Column field="invoice_account" header="Konto" style="max-width: 300px;">
                                            <template #body="slotProps">
                                                <AutoComplete dropdown :forceSelection="true" :completeOnFocus="true" placeholder="Konto wählen..." @complete="search_account" v-model="slotProps.data.invoice_account" :suggestions="filteredAccounts" optionLabel="name" :inputStyle="invoice_validation.custom_position_account == 'success' ? {'border-color':'#22c55e'} : {'border-color':'#e24c4c'}" class="w-full" />
                                            </template>
                                        </Column>
                                        <Column field="invoice_tax" header="Steuersatz" style="max-width: 300px;">
                                            <template #body="slotProps">
                                                <AutoComplete dropdown :forceSelection="true" :completeOnFocus="true" placeholder="Steuersatz wählen..." @complete="search_tax" v-model="slotProps.data.invoice_tax" :suggestions="filteredTaxes" optionLabel="name" :inputStyle="invoice_validation.custom_position_tax == 'success' ? {'border-color':'#22c55e'} : {'border-color':'#e24c4c'}" class="w-full" />
                                            </template>
                                        </Column>
                                        <Column field="total" header="Betrag">
                                            <template #body="slotProps">
                                                <InputNumber @focus="$event.target.select()" locale="de-CH" :minFractionDigits="2" :maxFractionDigits="2" v-model="slotProps.data.amount" :inputStyle="invoice_validation.custom_position_amount == 'success' ? {'border-color':'#22c55e'} : {'border-color':'#e24c4c'}" class="w-full" />
                                            </template>
                                        </Column>
                                        <Column>
                                            <template #body="slotProps">
                                                <Button @click="delete_position(slotProps.index)" v-tooltip.top="'Position löschen'" class="p-button-rounded p-button-text" icon="pi pi-trash" />
                                            </template>
                                        </Column>
                                    </DataTable>
                                    <div v-if="invoice_validation.custom_position_title != 'success'"><small class="p-error">Position-Beschreibung ist ein Pflichtfeld</small></div>
                                    <div v-if="invoice_validation.custom_position_account != 'success'"><small class="p-error">Bitte wählen Sie ein Konto aus</small></div>
                                    <div v-if="invoice_validation.custom_position_tax != 'success'"><small class="p-error">Bitte wählen Sie einen Steuersatz aus</small></div>
                                    <div v-if="invoice_validation.custom_position_amount != 'success'"><small class="p-error">Der Betrag in der Position kann nicht Null sein</small></div>
                                </div>
                                <div class="field col-12 mt-1">
                                    <Button @click="add_position()" v-tooltip.bottom="'Neue Position in Bexio hinzufügen'" label="Position hinzufügen" class="mr-2 p-button" icon="pi pi-plus" />
                                </div>
                                <div v-if="custom_discount_positions.length > 0" class="field col-12 mt-1">
                                    <DataTable :value="custom_discount_positions" :rowHover="true" class="p-datatable-sm" scrollable style="border: 1px solid #cbd5e1;">
                                        <Column field="title" header="Rabatt" style="min-width: 200px;">
                                            <template #body="slotProps">
                                                <InputText @focus="$event.target.select()" v-model="slotProps.data.title" :class="[invoice_validation.custom_position_discount_title == 'error' && 'border-red-500', invoice_validation.custom_position_discount_title == 'success' && 'border-green-500', invoice_validation.custom_position_discount_title == 'warn' && 'border-orange-500']" class="w-full" />
                                            </template>
                                        </Column>
                                        <Column field="percentage" header="Rabatt %" style="max-width: 100px;">
                                            <template #body="slotProps">
                                                <InputNumber style="width: 100px" @focus="$event.target.select()" @input="change_discount_position_values($event, slotProps)" locale="de-CH" suffix="%" :minFractionDigits="2" :maxFractionDigits="2" v-model="slotProps.data.percentage" :inputStyle="{'width': '100px'}" />
                                            </template>
                                        </Column>
                                        <Column field="amount" header="Betrag" style="max-width: 100px;">
                                            <template #body="slotProps">
                                                <InputNumber @focus="$event.target.select()" @input="change_discount_position_values($event, slotProps)" locale="de-CH" :minFractionDigits="2" :maxFractionDigits="2" v-model="slotProps.data.amount" :inputStyle="invoice_validation.custom_discount_position_amount == 'success' ? {'border-color':'#22c55e','width': '100px'} : {'border-color':'#e24c4c','width': '100px'}" class="w-full" />
                                            </template>
                                        </Column>
                                        <Column>
                                            <template #body="slotProps">
                                                <Button @click="delete_discount_position(slotProps.index)" v-tooltip.top="'Rabatt-Position löschen'" class="p-button-rounded p-button-text" icon="pi pi-trash" />
                                            </template>
                                        </Column>
                                    </DataTable>
                                    <div v-if="invoice_validation.custom_discount_position_title != 'success'"><small class="p-error">Position-Beschreibung ist ein Pflichtfeld</small></div>
                                    <div v-if="invoice_validation.custom_discount_position_amount != 'success'"><small class="p-error">Der Betrag in der Rabatt-Position darf nicht Null sein</small></div>
                                </div>
                                <div class="field col-12 mt-1">
                                    <Button @click="add_discount_position(0)" v-tooltip.bottom="'Neue Rabatt Position hinzufügen'" label="Rabatt" class="mr-2 p-button" icon="pi pi-plus" />
                                    <Button @click="add_discount_position(2)" v-tooltip.bottom="'Neue Rabatt Position hinzufügen'" label="2% Skonto " class="mr-2 p-button" />
                                    <Button @click="add_discount_position(3)" v-tooltip.bottom="'Neue Rabatt Position hinzufügen'" label="3% Skonto " class="mr-2 p-button" />
                                    <Button @click="add_discount_position(5)" v-tooltip.bottom="'Neue Rabatt Position hinzufügen'" label="5% Skonto" class="mr-2 p-button" />
                                </div>
                                <div v-if="invoice_title_status" class="field col-12 grid mt-3">
                                    <label for="invoice_title" class="col-12 mb-2 md:col-12 md:mb-2 lg:col-4 lg:mb-0 xl:col-4 xl:mb-0">Rechnungs-Titel</label>
                                    <div class="col-12 md:col-12 lg:col-8 xl:col-8">
                                        <InputText id="invoice_title" v-model="invoice_title" :class="[invoice_validation.invoice_title == 'error' && 'border-red-500', invoice_validation.invoice_title == 'success' && 'border-green-500', invoice_validation.invoice_title == 'warn' && 'border-orange-500']" class="w-full" />
                                        <small v-if="invoice_validation.invoice_title != 'success' && invoice_validation.invoice_title != 'warn'" class="p-error">Bitte einen Rechnungstitel eingeben</small>
                                    </div>
                                </div>
                                <div v-if="!invoice_title_status" class="field col-12 mt-1">
                                    <Button @click="invoice_title_status = true" v-tooltip.bottom="'Rechnungstitel bearbeiten'" label="Rechnungstitel" icon="pi pi-plus" />
                                </div>
                                <div class="field col-12 grid mt-3" v-if="invoice_currency">
                                    <label for="payment_amount" class="col-12 mb-2 md:col-12 md:mb-2 lg:col-4 lg:mb-0 xl:col-4 xl:mb-0">Rechnungsbetrag</label>
                                    <div class="col-12 md:col-12 lg:col-8 xl:col-8">
                                        <InputNumber v-model="payment_amount" placeholder="Rechnungsbetrag" locale="de-CH" mode="currency" :currency="String(invoice_currency)" :minFractionDigits="2" :maxFractionDigits="2" id="payment_amount" :inputStyle="payment_validation.payment_amount == 'success' ? {'border-color':'#22c55e','width': '100px'} : {'border-color':'#e24c4c','width': '100px'}" class="w-full" />
                                        <small v-if="payment_validation.payment_amount != 'success'" class="p-error">Bitte einen Rechnungsbetrag eingeben</small>
                                    </div>
                                </div>
                                <div class="field col-4" v-if="invoice_currency"></div>
                                <div class="field col-8" v-if="invoice_currency">
                                    <Badge class="mr-2 mb-1 bg-primary text-white" :value="'Total Brutto: ' + String(invoice_brutto)" v-tooltip.top="'Brutto'"></Badge>
                                    <Badge v-if="custom_discount_positions_total > 0" class="mr-2 mb-1 bg-green-700 text-white" :value="'Total Rabatt: ' + String(custom_discount_positions_total.toFixed(2))" v-tooltip.top="'Total Rabatt'"></Badge>
                                    <Badge v-if="custom_positions_difference != 0" class="mr-2 mb-1 bg-orange-700 text-white" :value="'Differenz: ' + String(custom_positions_difference.toFixed(2))" v-tooltip.top="'Differenz'"></Badge>
                                </div>
                                <div class="field col-12 grid mt-3" v-if="invoice_conversion_mode">
                                    <label class="col-12 mb-2 md:col-12 md:mb-2 lg:col-4 lg:mb-0 xl:col-4 xl:mb-0">Wechselkurs Modus</label>
                                    <div class="col-12 md:col-12 lg:col-8 xl:col-8">
                                        <div class="flex flex-wrap gap-3">
                                            <div class="flex align-items-center">
                                                <RadioButton v-model="conversion_type" inputId="daily_rate" name="daily_rate" value="daily" />
                                                <label for="daily_rate" class="ml-2">Tageskurs</label>
                                            </div>
                                            <div class="flex align-items-center">
                                                <RadioButton v-model="conversion_type" inputId="monthly_rate" name="monthly_rate" value="monthly" />
                                                <label for="monthly_rate" class="ml-2">Monatsmittelkurs</label>
                                            </div>
                                            <div class="flex align-items-center">
                                                <RadioButton v-model="conversion_type" inputId="manual_rate" name="manual_rate" value="manual" />
                                                <label for="manual_rate" class="ml-2">Manuell</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field col-12 grid mt-3" v-if="invoice_conversion_mode">
                                    <label class="col-12 mb-2 md:col-12 md:mb-2 lg:col-4 lg:mb-0 xl:col-4 xl:mb-0">Wechselkurs</label>
                                    <div class="col-12 md:col-12 lg:col-8 xl:col-8">
                                        <div class="flex align-content-center">
                                            <Calendar v-if="conversion_type == 'monthly'" id="conversion_month" :maxDate="new Date()" v-model="conversion_month" :manualInput="true" :showOnFocus="false" showIcon view="month" dateFormat="mm.yy" class="w-auto mr-2" />
                                            <Calendar v-if="conversion_type == 'daily'" id="conversion_day" :maxDate="new Date()" v-model="conversion_day" :manualInput="true" :showOnFocus="false" showIcon dateFormat="dd.mm.yy" class="w-auto mr-2" />
                                            <InputText :disabled="conversion_type == 'monthly' || conversion_type == 'daily' ? true : false" id="conversion_rate" v-model="conversion_rate" class="w-auto mr-2" :inputStyle="invoice_validation.conversion_rate == 'success' ? {'border-color':'#22c55e','width': '100px'} : {'border-color':'#e24c4c','width': '100px'}" />
                                        </div>
                                        <div class="flex align-content-center">
                                            <small v-if="conversion_type == 'monthly'" class="ml-2"><a class="text-primary" href="https://www.estv.admin.ch/estv/de/home/mehrwertsteuer/mwst-abrechnen/mwst-fremdwaehrungskurse/mwst-monatsmittelkurse.html" target="_blank">Monatsmittelkurse</a></small>
                                            <small v-if="conversion_type == 'daily'" class="ml-2"><a class="text-primary" href="https://www.six-group.com/en/products-services/the-swiss-stock-exchange/market-data/news-tools/currency-converter.html" target="_blank">Währungsrechner</a></small>
                                        </div>
                                    </div>
                                </div>
                                <div class="field col-12 grid mt-3" v-if="invoice_conversion_mode">
                                    <label class="col-12 mb-2 md:col-12 md:mb-2 lg:col-4 lg:mb-0 xl:col-4 xl:mb-0">Rechnungsbetrag (CHF)</label>
                                    <div class="col-12 md:col-12 lg:col-8 xl:col-8">
                                        <InputNumber placeholder="Rechnungsbetrag (CHF)" locale="de-CH" mode="currency" currency="CHF" :minFractionDigits="2" :maxFractionDigits="2" id="invoice_total_chf" v-model="converted_chf" class="w-full" />
                                    </div> 
                                </div>
                                <div class="field col-12 mt-3">
                                    <div class="flex flex-wrap gap-3">
                                        <div class="flex align-items-center">
                                            <RadioButton v-model="invoice_type" inputId="payment" name="payment" value="PAYMENT" />
                                            <label for="qr" class="ml-2">Zahlung erfassen</label>
                                        </div>
                                        <div class="flex align-items-center">
                                            <RadioButton v-model="invoice_type" inputId="open" name="open" value="OPEN" />
                                            <label for="iban" class="ml-2">Als offene Rechnung erfassen</label>
                                        </div>
                                        <div class="flex align-items-center hidden">
                                            <RadioButton v-model="invoice_type" inputId="paid" name="paid" value="PAID" />
                                            <label for="manual" class="ml-2">Als schon bezahlte Rechnung erfassen </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </AccordionTab>
                    </Accordion>
                </BlockUI>
            </div>

            <div v-if="invoice_type == 'PAYMENT'" class="field col-12 mt-3">
                <BlockUI :blocked="loader_payment">
                    <Accordion class="accordion-custom mt-3 shadow-1" :activeIndex="0">
                        <AccordionTab>
                            <template #header>
                                <span class="mr-2" style="font-size: 130%;">Bexio Zahlung</span>
                                <Avatar v-if="payment_status_counter > 0" :label="String(payment_status_counter)" class="mr-2 bg-red-100" shape="circle"  />
                                <Avatar v-if="payment_status_counter == 0" icon="pi pi-check" class="mr-2 bg-green-100" shape="circle"  />
                            </template>
                            <div class="col-12 formgrid grid">
                                <div v-if="swiss_qr_code" class="p-3 font-bold border-round border-1 border-green-500 bg-green-100 m-1 w-full"><i class="pi pi-qrcode mr-2" />Swiss QR-Barcode vorhanden</div>
                                <div class="field col-12 mt-3">
                                    <div class="flex flex-wrap gap-3">
                                        <div class="flex align-items-center">
                                            <RadioButton v-model="payment_type" inputId="qr" name="qr" value="QR" />
                                            <label for="qr" class="ml-2">QR</label>
                                        </div>
                                        <div class="flex align-items-center">
                                            <RadioButton v-model="payment_type" inputId="iban" name="iban" value="IBAN" />
                                            <label for="iban" class="ml-2">IBAN</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="field col-12 mt-3">
                                    <span>
                                        <Dropdown :filter="true" :showClear="true" placeholder="Bank wählen..." v-model="payment_bank_account" :options="bank_accounts" optionLabel="name" optionValue="id" :class="[payment_validation.payment_bank_account == 'error' && 'border-red-500', payment_validation.payment_bank_account == 'success' && 'border-green-500', payment_validation.payment_bank_account == 'warn' && 'border-orange-500']" class="w-full" />
                                    </span>
                                    <div v-if="payment_validation.payment_bank_account == 'error'"><small class="p-error">Bitte ein Bankkonto auswählen</small></div>
                                </div>
                                <div class="field col-6 mt-3">
                                    <span class="p-float-label">
                                        <Calendar id="payment_execution_date" v-model="payment_execution_date" :manualInput="true" :showOnFocus="false" showIcon dateFormat="dd.mm.yy" :class="payment_validation.payment_execution_date == 'error' ? 'p-invalid' : ''" :inputStyle="payment_validation.payment_execution_date == 'success' ? {'border-color':'#22c55e'} : {'border-color':'#e24c4c'}" class="w-full" />
                                        <label for="payment_execution_date">Ausführungsdatum</label>
                                    </span>
                                    <small v-if="payment_validation.payment_execution_date == 'error'" class="p-error">Bitte ein Ausführungsdatum eingeben</small>
                                </div>
                                <div class="field col-12 mt-3">
                                    <BlockUI :blocked="loader_iban">
                                        <span class="p-float-label">
                                            <InputText id="iban" v-model="payment_iban" class="w-full" :class="{'border-red-500':!iban_is_valid,'border-green-500':iban_is_valid}" />
                                            <label for="iban">IBAN</label>
                                        </span>
                                        <small v-if="payment_validation.payment_iban != 'success'" class="p-error">Bitte eine IBAN eingeben</small>
                                        <small v-show="!iban_is_valid" class="p-error">IBAN ist ungültig</small>
                                        <small v-show="iban_is_valid" class="text-green-500">{{ iban_validation_message }}</small>
                                    </BlockUI>
                                </div>
                                <div class="field col-6 mt-3">
                                    <span class="p-float-label">
                                        <InputNumber currency="CHF" locale="de-CH" id="amount" v-model="payment_amount" :inputStyle="payment_validation.payment_amount == 'success' ? {'border-color':'#22c55e','width': '100px'} : {'border-color':'#e24c4c','width': '100px'}" class="w-full" />
                                        <label for="amount">Betrag</label>
                                    </span>
                                    <small v-if="payment_validation.payment_amount != 'success'" class="p-error">Bitte einen Betrag eingeben</small>
                                </div>
                                <div class="field col-6 mt-3">
                                    <template v-if="payment_type == 'QR'">
                                        <span class="p-float-label">
                                            <InputText id="reference_number" @focus="$event.target.select()" v-model="payment_reference_number" :class="[payment_validation.payment_reference_number == 'error' && 'border-red-500', payment_validation.payment_reference_number == 'success' && 'border-green-500', payment_validation.payment_reference_number == 'warn' && 'border-orange-500']" class="w-full" />
                                            <label for="reference_number">Referenz-Nummer</label>
                                        </span>
                                        <small v-if="payment_validation.payment_reference_number != 'success'" class="p-error">Bitte eine Referenznummer eingeben</small>
                                    </template>
                                </div>
                                <div class="field col-6 mt-3">
                                    <span class="p-float-label">
                                        <Textarea id="message" style="width:100%; min-height: 50px;" :autoResize="true" v-model="payment_message" />
                                        <label for="message">Nachricht an Empfänger</label>
                                        <small>{{ payment_message_counter }} / 140 verbleibende Zeichen</small>
                                    </span>
                                </div>
                                <div class="field col-6 mt-3">
                                    <span class="p-float-label">
                                        <Textarea id="booking_text" style="width:100%; min-height: 50px;" :autoResize="true" v-model="payment_booking_text" />
                                        <label for="booking_text">Buchungstext</label>
                                        <small>{{ payment_booking_text_counter }} / 35 verbleibende Zeichen</small>
                                    </span>
                                </div>
                                <div class="field col-12 mt-3">
                                    <span class="p-float-label">
                                        <InputText id="payment_name" type="text" v-model="payment_name" :class="[payment_validation.payment_name == 'error' && 'border-red-500', payment_validation.payment_name == 'success' && 'border-green-500']" class="w-full" />
                                        <label for="payment_name">Empfängernamen</label>
                                    </span>
                                    <small v-if="payment_validation.payment_name != 'success'" class="p-error">Bitte Empfängernamen ausfüllen</small>
                                </div>
                                <div class="field col-12 mt-3">
                                    <span class="p-float-label">
                                        <InputText id="payment_address" type="text" v-model="payment_address" :class="[payment_validation.payment_address == 'error' && 'border-red-500', payment_validation.payment_address == 'success' && 'border-green-500']" class="w-full" />
                                        <label for="payment_address">Strasse</label>
                                    </span>
                                    <small v-if="payment_validation.payment_address != 'success'" class="p-error">Bitte Strasse ausfüllen</small>
                                </div>
                                <div class="field col-6 mt-3">
                                    <span class="p-float-label">
                                        <InputText id="payment_postcode" type="text" v-model="payment_postcode" :class="[payment_validation.payment_postcode == 'error' && 'border-red-500', payment_validation.payment_postcode == 'success' && 'border-green-500']" class="w-full" />
                                        <label for="payment_postcode">PLZ</label>
                                    </span>
                                    <small v-if="payment_validation.payment_postcode != 'success'" class="p-error">Bitte PLZ ausfüllen</small>
                                </div>
                                <div class="field col-6 mt-3">
                                    <span class="p-float-label">
                                        <InputText id="payment_city" type="text" v-model="payment_city" :class="[payment_validation.payment_city == 'error' && 'border-red-500', payment_validation.payment_city == 'success' && 'border-green-500']" class="w-full" />
                                        <label for="payment_city">Ort</label>
                                    </span>
                                    <small v-if="payment_validation.payment_city != 'success'" class="p-error">Bitte Ort ausfüllen</small>
                                </div>
                                <div class="field col-6 mt-3">
                                    <span>
                                        <Dropdown class="w-full" :filter="true" :showClear="true" placeholder="Land wählen..." v-model="payment_country" :options="countries" optionLabel="name" optionValue="id" :class="[payment_validation.payment_country == 'error' && 'border-red-500', payment_validation.payment_country == 'success' && 'border-green-500']" />
                                    </span>
                                    <small v-if="payment_validation.payment_country != 'success'" class="p-error">Bitte Land auswählen</small>
                                </div>
                            </div>
                        </AccordionTab>
                    </Accordion>
                </BlockUI>
            </div>
            <div class="field col-12 mt-2">
                <div class="mt-5">
                    <MultiSelect v-model="selected_users" display="chip" :options="users" optionLabel="name" optionValue="email" placeholder="Benutzer auswählen" class="w-auto mb-1 mr-1" />
                    <Button :disabled="selected_users.length == 0" label="Zur Freigabe weiterleiten" class="w-auto mr-2 mb-1 p-button" icon="pi pi-check-square" @click="send_approval_workflow" />
                </div>
                <div class="mt-5">
                    <Button @click="save_workflow" label="Änderungen Speichern" class="w-auto mr-2 mb-1 border-green-800 bg-green-700 text-white" icon="pi pi-save" />
                    <Button label="Zurücksetzen" class="w-auto mr-2 mb-1 p-button-warning" icon="pi pi-refresh" />
                </div>
                <div class="mt-5">
                    <Button :disabled="!workflow_status" @click="create_payment" size="large" label="Bexio senden" class="w-auto mr-2 mb-1" style="background-color: #0d2f3b; color: #bbdc00" icon="pi pi-cloud-upload" />
                </div>
                <small v-if="!workflow_status" class="p-error">Bitte füllen Sie alle Pflichtfelder aus</small>
            </div>
        </div>
        <div :class="'field xl:col-'+pdf_size+' lg:col-'+pdf_size+' mt-3 md:col-12'">
            <div class="p-1">
                <Button v-if="pdf_size == 4" @click="pdf_size = 6, workflow_size = 6" icon="pi pi-arrow-left" class="p-2 mr-2" v-tooltip.top="'PDF vergrössen'" />
                <Button v-if="pdf_size == 6" @click="pdf_size = 4, workflow_size = 8" icon="pi pi-arrow-right" class="p-2 mr-2" v-tooltip.top="'PDF verkleinern'" />
                <Button icon="pi pi-download" @click="download_pdf(pdf_url, str(invoice_number_external)+'.pdf')" class="p-2 mr-2" v-tooltip.top="'PDF herunterladen'" />
                <Button icon="pi pi-external-link" @click="open_pdf()" class="p-2 mr-2" v-tooltip.top="'PDF in einem neues Fenster öffnen'" />
            </div>
            <div class="border-solid border-400 border-2 shadow-5 z-auto bg-gray-100" style="position: sticky; top: 0">
                <PDFEmbed :pdf_url="pdf_url" />
            </div>
        </div>
    </div>
    </BlockUI>
    <!-------------------------------------------------------->
    <!------------------ Create Supplier --------------------->
    <!-------------------------------------------------------->
    <Dialog v-model:visible="newSupplierDialog" :style="{width: '700px'}" header="Lieferant in Bexio hinzufügen" :modal="true" class="p-fluid z-5">
        <div v-if="supplier_exists" class="p-3 border-round border-1 border-red-500 bg-red-100 font-bold w-full">Lieferant {{ supplier_name }} existiert bereits im Bexio!</div>
        <div v-if="supplier_exists">
            <DataTable class="mt-3 w-full" @row-click="select_bexio_contact" :value="bexio_search_results" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20,50,100]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                <Column field="name_1" header="Name" sortable class="font-bold" />
                <Column field="address" header="Adresse" sortable />
                <Column field="postcode" header="PLZ" sortable />
                <Column field="city" header="Ort" sortable />
            </DataTable>
        </div>
        <div v-if="!supplier_exists" class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="supplier_name" type="text" v-model="supplier_name" :class="{'p-invalid':!supplier_name_status,'border-green-500':supplier_name_status}" />
                    <label for="supplier_name">Lieferantennamen</label>
                </span>
                <small v-if="!supplier_name_status" class="p-error">Bitte geben Sie einen Namen ein</small>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="supplier_address" type="text" v-model="supplier_address" :class="{'p-invalid':!supplier_address_status,'border-green-500':supplier_address_status}" />
                    <label for="supplier_address">Strasse</label>
                </span>
                <small v-if="!supplier_address_status" class="p-error">Bitte geben Sie eine Adresse ein</small>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText id="supplier_postcode" type="text" v-model="supplier_postcode" :class="{'p-invalid':!supplier_postcode_status,'border-green-500':supplier_postcode_status}" />
                    <label for="supplier_postcode">PLZ</label>
                </span>
                <small v-if="!supplier_postcode_status" class="p-error">Bitte geben Sie eine Postleitzahl ein</small>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText id="supplier_city" type="text" v-model="supplier_city" :class="{'p-invalid':!supplier_city_status,'border-green-500':supplier_city_status}" />
                    <label for="supplier_city">Ort</label>
                </span>
                <small v-if="!supplier_city_status" class="p-error">Bitte geben Sie eine Stadt ein</small>
            </div>
            <div class="field col-6 mt-3">
                <span>
                    <Dropdown class="w-full" :filter="true" :showClear="true" placeholder="Land wählen..." v-model="supplier_country" :options="countries" optionLabel="name" optionValue="id" :class="{'p-invalid':!supplier_country_status,'border-green-500':supplier_country_status}" />
                </span>
                <small v-if="!supplier_country_status" class="p-error">Bitte geben Sie ein Land ein</small>
            </div>
            <div class="field col-6 mt-3">
                <span>
                    <Dropdown class="w-full" :filter="true" placeholder="Sprache wählen..." v-model="supplier_language" :options="languages" optionLabel="name" optionValue="id" />
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText id="supplier_email" type="text" v-model="supplier_email" />
                    <label for="supplier_email">E-Mail</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText id="supplier_phone" type="text" v-model="supplier_phone" />
                    <label for="supplier_phone">Telefon</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <div>
                    <Button :disabled="create_supplier_button_check ? false : true" label="Lieferant hinzufügen" @click="create_bexio_supplier()" class="w-auto mr-2 mb-1 p-button" style="background-color: #0d2f3b; color: #bbdc00" icon="pi pi-save" />
                    <Button label="Abbrechen" @click="newSupplierDialog = false" class="w-auto mr-2 mb-1 p-button-danger" icon="pi pi-times" />
                </div>
                <small v-if="!create_supplier_button_check" class="p-error">Bitte alle Pflichtfelder ausfüllen</small>
            </div>
        </div>
    </Dialog>
    <!------------------------------------------------->
    <!--------- Bexio Search Dialog ------------------->
    <!------------------------------------------------->
    <Dialog v-model:visible="searchSupplierDialog" :style="{width: '1000px'}" header="Lieferant in Bexio suchen" :modal="true" class="p-fluid z-5">    
        <BlockUI :blocked="bexio_search_loader">
            <Toolbar>
                <template #start>
                    <InputText v-model="bexio_search_string" placeholder="Kontaktnamen Suchen" style="width: 300px" class="mr-3" />
                    <Button @click="bexio_search" v-tooltip.bottom="'Kontakte in Bexio suchen'" label="Suchen" style="background-color: #0d2f3b; color: #bbdc00" class="mr-2 p-button" icon="pi pi-search" />
                </template>
                <template #end>
                    <Button @click="searchSupplierDialog = false, new_supplier()" v-tooltip.bottom="'Neuen Lieferant in Bexio erfassen'" label="Lieferant hinzufügen" class="mr-2 p-button" icon="pi pi-plus" />
                </template>
            </Toolbar>
            <!--------------------------------------------------->
            <!------------------ DataTable ---------------------->
            <!--------------------------------------------------->
            <DataTable class="mt-3 w-full" @row-click="select_bexio_contact" :value="bexio_search_results" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20,50,100]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                <Column field="name_1" header="Name" sortable class="font-bold" />
                <Column field="address" header="Adresse" sortable />
                <Column field="postcode" header="PLZ" sortable />
                <Column field="city" header="Ort" sortable />
            </DataTable>
        </BlockUI>
    </Dialog>
    <!------------------------------------------------->
    <!-------------- Support Dialog ------------------->
    <!------------------------------------------------->
    <Dialog v-model:visible="supportFormDialog" :style="{width: '1000px'}" header="Support Formular" :modal="true" class="p-fluid z-5">    
        <div class="col-12 formgrid grid">
            <div class="field col-12">
            <div class="p-2">
                Hast du einen Fehler gefunden? Hast du einen Verbesserungsvorschlag? Oder einfach eine allgemeine Frage? Zögere nicht, uns ein Ticket zu schicken, und wir werden uns umgehend bei dir melden.
            </div>
            </div>
            <div class="field col-12 mt-3" hidden>
                <span class="p-float-label">
                    <InputText id="workflow_id" disabled type="text" v-model="support_details.workflow_id" />
                    <label for="workflow_id">Rechnungs-ID</label>
                </span>
            </div>
            <div class="field col-12 mt-3" hidden>
                <span class="p-float-label">
                    <InputText id="customer_id" disabled type="text" v-model="support_details.customer_id" />
                    <label for="customer_id">Kunden-ID</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <Editor editorStyle="width: 100%; min-height: 350px;" v-model="support_details.details">
                    <template v-slot:toolbar>
                        <span class="ql-formats">
                            <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                            <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                            <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                            <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                            <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                            <button class="ql-image" v-tooltip.bottom="'Bild hochladen'"></button>
                        </span>
                    </template>
                </Editor>
                <small>Wir wissen bereits, um welche Rechnung es sich handelt. Sende uns eine kurze Beschreibung. Sie können Bilder/Screenshots direkt einfügen.</small>
            </div>
            <div class="field col-12 mt-3">
                <Button :disabled="!support_details.details ? true : false" @click="send_support_ticket" label="Ticket senden" class="w-auto" icon="pi pi-send" />
            </div>
        </div>  
    </Dialog>
</template>