<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"

// Components
import KrediflowWorkflow from './Krediflow-Workflow.vue'
import BexioInbox from './Bexio-Inbox.vue'
import { useAuthStore } from '@/core/store/AuthStore'

////////////
// System //
////////////

const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
const auth = useAuthStore()

//////////////////
// Data Sources //
//////////////////

const inbox = ref([])
const open = ref([])
const archive = ref([])
const cancelled = ref([])
const thumb_index = ref(0)

const users = ref([])

const filter_inbox = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})

const workflow_tabs = ref([])
const context_menu = ref()
const context_menu_param = ref()
const context_menu_users = ref()
const context_menu_items = ref([])
const inbox_section = ref("inbox")
const active_workflow = ref("inbox")
const bexio_inbox_dialog = ref(false)

// on page load...
onMounted( async () => {
    // Get Inbox Data
    init_data()
})

const init_data = async () => {
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/senler/get-inbox")
        .then(response => {
            console.log("get-inbox", response.data)
            open.value = response.data.open
            archive.value = response.data.archived
            cancelled.value = response.data.cancelled
            if (inbox_section.value == "inbox") {
                inbox.value = open.value
            }
            if (inbox_section.value == "archive") {
                inbox.value = archive.value
            }
            if (inbox_section.value == "cancelled") {
                inbox.value = cancelled.value
            }
    })

    await axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/senler/get-users")
    .then(response => {
        users.value = response.data
        context_menu_items.value = [
            {
                label: 'Öffnen',
                icon: 'pi pi-fw pi-pencil',
                command: (e) => open_workflow(context_menu_param.value)
            },
            {
                label: 'Weiterleiten',
                icon: 'pi pi-fw pi-arrow-right',
                items: get_users_for_menu()
            },
            {
                seperator: true
            },
            {
                label: 'Löschen',
                icon: 'pi pi-fw pi-times',
                command: (e) => cancel_workflow(context_menu_param.value)
            }
        ]
    })
}

///////////////
// Interface //
///////////////

const editor_callback = (e) => {
    if (e.action == "close_tab") {
        close_tab(e.workflow_id)
    }
}

const close_tab = (workflow_id) => {
    for (let key in workflow_tabs.value){
        if (workflow_tabs.value[key]["workflow_id"] == workflow_id){
            workflow_tabs.value.splice(key, 1)
            console.log("workflow_tabs", workflow_tabs.value.length)
            if (workflow_tabs.value.length == 0) {
                active_workflow.value = "inbox"
            } else {
                active_workflow.value = workflow_tabs.value[workflow_tabs.value.length - 1]["workflow_id"]
            }
        }
    }
}

//when opening a workflow
const open_workflow = (e) => {
    context_menu.value.hide()
    let workflow_id = e.data.workflow_id
    for (let key in inbox.value){
        if (inbox.value[key].workflow_id == workflow_id) {
            let found = workflow_tabs.value.some((el) => el.workflow_id === workflow_id);
            if (!found) {
                let name = ""
                if (inbox.value[key].workflow_id > 25) {
                    name = inbox.value[key].invoice_number.substring(0, 25) + "..."
                } else {
                    name = inbox.value[key].invoice_number
                }
                workflow_tabs.value.push({
                    "workflow_id": inbox.value[key].workflow_id,
                    "name": name
                })
                active_workflow.value = inbox.value[key].workflow_id
            }
            else
            {
                active_workflow.value = e.data.workflow_id
            }
        }
    }
}

const show_context_menu = (e) => {
    context_menu.value.show(e.originalEvent)
    context_menu_param.value = e
}

const forward_workflow = (e) => {
    console.log("forward workflow", e)
}

const cancel_workflow = (e) => {
    console.log("cancel workflow", e)
    if (e.data.status == "cancelled") {
        confirm.require({
            message: 'Wollen Sie wirklich den Workflow löschen?',
            header: 'Workflow löschen',
            rejectLabel: 'Nein',
            acceptLabel: 'Ja, Workflow löschen',
            acceptClass: 'p-button-danger',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                let payload = {
                "workflow_id": e.data.workflow_id,
                "editor": auth.userdata.user_email
                }
                axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/senler/cancel-workflow", payload)
                .then(response => {
                    if (response.data.status == "success") {
                        init_data()
                        toast.add({severity: 'success', summary: 'Rechnung endgültig gelöscht!', detail: 'Rechnung wurde endgültig gelöscht', life: 3000})
                    }
                })
            }
        })
    } else {
        confirm.require({
            message: 'Wollen Sie wirklich den Workflow abbrechen?',
            header: 'Workflow abbrechen',
            rejectLabel: 'Nein',
            acceptLabel: 'Ja, Workflow abbrechen',
            acceptClass: 'p-button-danger',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                let payload = {
                "workflow_id": e.data.workflow_id,
                "editor": auth.userdata.user_email
                }
                axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/senler/cancel-workflow", payload)
                .then(response => {
                    if (response.data.status == "success") {
                        init_data()
                        toast.add({severity: 'success', summary: 'Rechnung wurde abgebrochen', detail: 'Rechnung wurde erfolgreich abgebrochen', life: 3000})
                    }
                })
            }
        })
    }
}

const get_bexio_inbox = () => {
    bexio_inbox_dialog.value = true
}

////////////////
// Formatting //
////////////////

const swiss_date = (date) => {
    return new Date(date).toLocaleDateString('de-CH', { year: 'numeric', month: '2-digit', day: '2-digit' })
}

const swiss_date_time = (date) => {
    return new Date(date).toLocaleDateString('de-CH', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })
}

const get_users_for_menu = () => {
    let users_for_menu = [{label: 'Rollen', icon: 'pi pi-fw pi-users', items: []}]
    for (let key in users.value){
        users_for_menu.push({
            label: users.value[key].user_fullname,
            icon: 'pi pi-fw pi-user',
            command: (e) => forward_workflow(context_menu_param.value, users.value[key].id)
        })
    }
    return users_for_menu
}

const toggle_active_workflow = (e) => {
    if (e == "inbox") {
        active_workflow.value = "inbox"
    }
    else {
        if(workflow_tabs.value.some(obj => obj.workflow_id == e)) {
            active_workflow.value = e
        }
        else {
            if (workflow_tabs.value.length == 0){
                active_workflow.value = "inbox"
            }
        }
    }
}
</script>

<style scoped>
.inbox-datatable tr .meta-data {
    display: none;
}
.inbox-datatable tr:hover .meta-data {
    display: inline;
}
.tab-primrary {
    background-color: #3751ff;
    color: #FFFFFF;
}
.tab-inactive {
    background-color: #0d2f3b;
    color: #FFFFFF;
}
.tab-hidden {
    display: none;
}
</style>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <Toast />
    <ConfirmDialog />
    <ContextMenu ref="context_menu" :model="context_menu_items" />
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <Toolbar>
        <template #start>
            <Button rounded label="Inbox" icon="pi pi-inbox" v-tooltip.top="'Snappy Inbox'" @click="toggle_active_workflow('inbox')" :class="{'tab-primary': active_workflow == 'inbox','tab-inactive': active_workflow != 'inbox'}" class="mr-2" />
            <Button rounded v-for="(workflow_tab, key) in workflow_tabs" :key="key" @click="toggle_active_workflow(workflow_tab.workflow_id)" :class="{'tab-primary': active_workflow == 'inbox','tab-inactive': active_workflow != workflow_tab.workflow_id}" class="mr-2"><i class="pi pi-file mr-3"/>{{workflow_tab.name}}<i class="pi pi-times-circle ml-3 text-red-100" @click="close_tab(workflow_tab.workflow_id)" /></Button>
        </template>
    </Toolbar>
        <div v-if="active_workflow == 'inbox'" class="mt-3">
            <Toolbar>
                <template #start>
                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText v-model="filter_inbox['global'].value" placeholder="Suche" style="width: 300px" class="mr-4" />
                    </span>
                    <span v-if="inbox_section == 'inbox'" class="mr-4 font-bold">Offen<Badge :value="open.length" class="bg-gray-300 text-gray-600 ml-1" /></span>
                    <span v-if="inbox_section != 'inbox'" @click="inbox_section = 'inbox', inbox = open" class="mr-4 cursor-pointer">Offen<Badge :value="open.length" class="bg-gray-300 text-gray-600 ml-1" /></span>
                    <span v-if="inbox_section == 'archive'" class="mr-4 font-bold">Erledigt<Badge :value="archive.length" class="bg-gray-300 text-gray-600 ml-1" /></span>
                    <span v-if="inbox_section != 'archive'" @click="inbox_section = 'archive', inbox = archive" class="mr-4 cursor-pointer">Erledigt<Badge :value="archive.length" class="bg-gray-300 text-gray-600 ml-1" /></span>
                    <span v-if="inbox_section == 'cancelled'" class="mr-4 font-bold">Papierkorb<Badge :value="cancelled.length" class="bg-gray-300 text-gray-600 ml-1" /></span>
                    <span v-if="inbox_section != 'cancelled'" @click="inbox_section = 'cancelled', inbox = cancelled" class="mr-4 cursor-pointer">Papierkorb<Badge :value="cancelled.length" class="bg-gray-300 text-gray-600 ml-1" /></span>
                </template>
                <template #end>
                    <Button @click="get_bexio_inbox()" v-tooltip.bottom="'Rechnungen aus der Bexio Inbox laden'" label="Bexio" class="mr-2 p-button" style="background-color: #0d2f3b; color: #bbdc00" icon="pi pi-download" />
                    <Button @click="get_bexio_inbox()" v-tooltip.bottom="'Rechnungen aus der E-Mail Inbox laden'" label="E-Mails" class="mr-2 p-button" icon="pi pi-envelope" />
                </template>
            </Toolbar>
            <!---------------------------------------------------->
            <!------------------ DataTables ---------------------->
            <!---------------------------------------------------->
            <DataTable class="mt-3 inbox-datatable" v-model:filters="filter_inbox" :value="inbox" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20,50,100]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}" @row-click="open_workflow" @row-contextmenu="show_context_menu" dataKey="worfklow_id">
                <Column field="preview" style="width: 10rem">
                    <template #body="slotProps">
                        <div class="overflow-hidden shadow-2 w-9rem h-4rem" @mouseover="thumb_index = slotProps.index">
                            <Image v-if="slotProps.data.preview.length > 0" :src="slotProps.data.preview[0]" alt="Image" width="250" preview />
                        </div>
                    </template>
                </Column>
                <Column field="workflow_name" header="Belege" sortable>
                    <template #body="slotProps">
                        <div class="mt-2">
                            <Chip class="pl-0 pr-3 mr-2">
                                <span class="bg-primary border-circle w-2rem h-2rem flex align-items-center justify-content-center"><i class="pi pi-file" /></span>
                                <span class="ml-2 font-medium">{{slotProps.data.invoice_number}}</span>
                            </Chip>
                            <span class="font-bold mr-4 white-space-nowrap">{{slotProps.data.supplier_name}}</span>
                            <Badge class="mr-2 mb-1 bg-primary text-white hidden" :value="slotProps.data.workflow_name" v-tooltip.top="'Rechnungsnummer'"></Badge>
                            <Badge class="mr-2 mb-1 bg-primary text-white" v-if="slotProps.data.invoice_amount" value="Rechnung" v-tooltip.top="'Belegtyp: Rechnung'"></Badge>
                            <Badge class="mr-2 mb-1 bg-red-500 text-white" v-if="slotProps.data.invoice_amount" :value="slotProps.data.invoice_currency + ' ' + slotProps.data.invoice_amount" v-tooltip.top="'Rechnungsbetrag'"></Badge>
                            <Badge class="mr-2 mb-1 bg-gray-100 text-black-alpha-50" v-if="slotProps.data.email_from" :value="slotProps.data.email_from" v-tooltip.top="'Absender E-Mail'"></Badge>
                            <span hidden class="text-black-alpha-50 text-sm mr-2 white-space-nowrap">{{slotProps.data.email_subject}}</span>
                        </div>
                    </template>
                </Column>
                <Column field="email_date" header="Eingang" sortable>
                    <template #body="slotProps">
                        {{swiss_date(slotProps.data.email_date)}}
                    </template>
                </Column>
                <Column field="workflow_id" hidden></Column>
                <Column field="email_subject" hidden></Column>
                <Column field="supplier_name" hidden></Column>
                <Column field="invoice_amount" hidden></Column>
                <Column field="invoice_number" hidden></Column>
                <Column field="email_from" hidden></Column>
                <Column field="status" header="Status" sortable>
                    <template #body="slotProps">
                        <Badge v-if="slotProps.data.status == 'open'" class="mr-2 mb-1 bg-primary text-white" value="Offen" />
                        <Badge v-if="slotProps.data.status == 'closed'" class="mr-2 mb-1 bg-green-700 text-white" value="Erledigt" />
                        <Badge v-if="slotProps.data.status == 'cancelled'" class="mr-2 mb-1 bg-red-700 text-white" value="Abgebrochen" />
                    </template>
                </Column>
            </DataTable>
        </div>
        <div v-for="(workflow_tab, key) in workflow_tabs" :key="key" :hidden="active_workflow != workflow_tab.workflow_id">
            <KrediflowWorkflow :workflow_id="workflow_tab.workflow_id" @editor-callback="editor_callback" />
        </div>
    <!--------------------------------------------------->
    <!-------------- Bexio Inbox Dialog ----------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="bexio_inbox_dialog" :style="{width: '80%'}" header="Bexio Posteingang" :modal="true" >
        <BexioInbox />
    </Dialog>
</template>